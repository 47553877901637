import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FinalForm from "../Utils/FinalForm";
import InputText from "../Inputs/InputText";
import { useSnackbar } from "notistack";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddIcon from "@material-ui/icons/Add";
import { height } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/welcome.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "50%",
    borderRadius: 25,
    backgroundColor: "#00638F",
  },
  label: {
    marginTop: 20,
    marginLeft: 20,
    fontSize: 22,
    color: "rgb(134 134 134)",
    marginBottom: "-12px",
  },
  fileInput: {
    borderRadius: 25,
    backgroundColor: "#00638F",
    width: "50%",
    color: "#ffffff",
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column !important",
    flexWrap: "wrap",
    color: "#ffffff",
  },
  backButton: {
    marginLeft: 20,
    marginTop: 20,
  },
}));

export default function AddChecklist({ firebase }) {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { shipId } = useParams();

  const [items, setItems] = useState([]);
  const [item, setItem] = useState("");
  const [checked, setChecked] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const handleSubmit = async (values) => {
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("checklists")
      .add({
        date: new Date(),
        name: values?.name,
        items: items || [],
        validated: false,
      })
      .then(() => {
        enqueueSnackbar("Évènement créé !", { variant: "success" });
        history.push("/ship/" + shipId + "/checklist");
      })
      .catch((err) => {
        enqueueSnackbar("Erreur lors de la création de l'évènement", {
          variant: "error",
        });
        console.log(err);
      });
  };

  const handlePush = (e) => {
    if (e.key === "Enter" && item != "") {
      e.preventDefault();
      setItems((items) => [...items, { title: item, checked: checked }]);
      setItem("");
    }
  };

  const handleAdd = () => {
    if (item != "") {
      setItems((items) => [...items, { title: item, checked: checked }]);
      setItem("");
    }
  };

  const handleDelete = (index) => {
    let item = [...items];
    item.splice(index, 1);
    setItems(item);
  };

  console.log(items);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={8}>
        <Typography
          component="h1"
          variant="h1"
          style={{
            fontFamily: "MostraThree",
            fontSize: 50,
            color: "#00638F",
            textDecoration: "none",
            textAlign: "center",
            margin: "50px auto",
          }}
        >
          MAINT & SEA
        </Typography>
        <Button
          color="primary"
          variant="outlined"
          startIcon={<ArrowBack color="primary" />}
          className={classes.backButton}
          onClick={() => history.goBack()}
        >
          Retour
        </Button>
        <div
          style={{
            padding: "5%",
          }}
        >
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              marginTop: 20,
              color: "#00638F",
              fontSize: 40,
              textAlign: "center",
            }}
          >
            Ajouter un évènement
          </Typography>
          <FinalForm
            onSubmit={handleSubmit}
            style={classes.form}
            initialValues={{
              date: new URLSearchParams(location.search).get("date"),
            }}
          >
            <p className={classes.label}>Nom</p>
            <InputText name="name" required type="text"></InputText>

            <p className={classes.label}>Nouvelle entrée</p>
            <div
              style={{
                display: "flex",
                padding: "18.5px 0",
                alignItems: "center",
              }}
            >
              <TextField
                value={item}
                style={{ width: "100%", padding: "18.5px 14px" }}
                name="item"
                onKeyPress={(e) => handlePush(e)}
                onChange={(e) => setItem(e.target.value)}
                type="text"
              ></TextField>
              <AddIcon
                style={{ cursor: "pointer", color: "gray" }}
                onClick={handleAdd}
              />
            </div>
            <div classeName={classes.list}>
              {items.map((item, index) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0 14px",
                    flexWrap: "wrap",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginLeft: "5px" }}>{item.title}</p>
                    <DeleteForeverIcon
                      style={{
                        cursor: "pointer",
                        fontSize: "17px",
                        color: "red",
                      }}
                      onClick={() => handleDelete(index)}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
              className={classes.btnWrapper}
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Créer
              </Button>
            </div>
          </FinalForm>
        </div>
      </Grid>
    </Grid>
  );
}
