import {
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  Add,
  CloudDownload,
  CloudUpload,
  CreateNewFolder,
  Delete,
  Description,
  Edit,
  FolderOpen,
  Home,
} from "@material-ui/icons";
import { set } from "date-fns";
import { is, tr } from "date-fns/locale";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";

const Storage = ({ firebase, shipId }) => {
  const classes = useStyles();
  const [path, setPath] = useState(["home"]);
  const shipRef = useMemo(
    () => firebase.db.collection("Ships").doc(shipId),
    [shipId]
  );
  const [isAddingFile, setIsAddingFile] = useState(false);
  const [isAddingFolder, setIsAddingFolder] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [newFile, setNewFile] = useState(null);
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [newName, setNewName] = useState("");
  const [search, setSearch] = useState("");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    setLoading(true);
    shipRef
      .collection("storage")
      .where("path", "==", path.join("/"))
      .get()
      .then(({ docs }) => {
        setContent(
          docs.map((doc) => ({ ...doc.data(), id: doc.id, ref: doc.ref }))
        );
      })
      .finally(() => setLoading(false));
  }, [path]);

  useEffect(() => {
    setNewFile(null);
    setNewName("");
  }, [isAddingFile, isAddingFolder]);

  const uploadFile = async () => {
    setUploading(true);
    try {
      const storageRef = firebase.storage.ref(
        `Ships/${shipId}/Storage/${path.join("/")}/${newName}`
      );
      const uploadTask = await storageRef.put(newFile);
      const url = await uploadTask.ref.getDownloadURL();
      const data = {
        name: newName,
        url,
        path: path.join("/"),
        storageRef: storageRef.fullPath,
        createdAt: new Date(),
        type: "file",
      };
      const newRef = await shipRef.collection("storage").add(data);
      setContent([...content, { ...data, id: newRef.id, ref: newRef }]);
      setNewFile(null);
      setNewName("");
      setIsAddingFile(false);
      enqueueSnackbar("Fichier ajouté", { variant: "success" });
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Erreur lors de l'envoi du fichier", {
        variant: "error",
      });
    } finally {
      setUploading(false);
    }
  };

  const downloadFile = async (file) => {
    const a = document.createElement("a");
    a.href = file.url;
    a.download = file.name;
    a.target = "_blank";
    a.click();
    a.remove();
  };

  const addFolder = async () => {
    setUploading(true);
    try {
      const data = {
        name: newName,
        path: path.join("/"),
        createdAt: new Date(),
        type: "folder",
      };

      const newRef = await shipRef.collection("storage").add(data);
      setNewName("");
      setContent([...content, { ...data, id: newRef.id, ref: newRef }]);
      setIsAddingFolder(false);
      enqueueSnackbar("Dossier ajouté", { variant: "success" });
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Erreur lors de l'ajout du dossier", {
        variant: "error",
      });
    } finally {
      setUploading(false);
    }
  };

  const deleteFile = (file) => {
    enqueueSnackbar("Voulez-vous vraiment supprimer ce fichier ?", {
      variant: "warning",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      action: (key) => (
        <>
          <Button
            style={{ marginRight: 10 }}
            onClick={() => {
              closeSnackbar(key);
            }}
            variant="contained"
          >
            Annuler
          </Button>
          <Button
            onClick={async () => {
              try {
                const fileRef = firebase.storage.ref(file.storageRef);
                await Promise.all([fileRef.delete(), file.ref.delete()]);
                setContent(content.filter((e) => e.id !== file.id));
                closeSnackbar(key);
                enqueueSnackbar("Fichier supprimé", { variant: "success" });
              } catch (e) {
                console.error(e);
                enqueueSnackbar("Erreur lors de la suppression", {
                  variant: "error",
                });
                closeSnackbar(key);
              }
            }}
            startIcon={<Delete style={{ color: "red" }} />}
            variant="contained"
          >
            Supprimer
          </Button>
        </>
      ),
    });
  };

  const deleteFolder = (folder) => {
    enqueueSnackbar(
      "Voulez-vous vraiment supprimer ce dossier ? Tous les fichiers contenus à l'intérieur seront également supprimés",
      {
        variant: "warning",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        action: (key) => (
          <>
            <Button
              style={{ marginRight: 10 }}
              onClick={() => {
                closeSnackbar(key);
              }}
              variant="contained"
            >
              Annuler
            </Button>
            <Button
              onClick={async () => {
                try {
                  const folderContent = await shipRef
                    .collection("storage")
                    .where("path", ">=", folder.path + "/" + folder.name)
                    .where("type", "==", "file")
                    .get();

                  await Promise.all([
                    folder.ref.delete(),
                    ...folderContent.docs.flatMap((doc) => {
                      return [
                        doc.ref.delete(),
                        firebase.storage.ref(doc.data().storageRef).delete(),
                      ];
                    }),
                  ]);
                  setContent(content.filter((e) => e.id !== folder.id));
                  closeSnackbar(key);
                  enqueueSnackbar("Dossier supprimé", { variant: "success" });
                } catch (e) {
                  console.error(e);
                  enqueueSnackbar("Erreur lors de la suppression", {
                    variant: "error",
                  });
                  closeSnackbar(key);
                }
              }}
              startIcon={<Delete style={{ color: "red" }} />}
              variant="contained"
            >
              Supprimer
            </Button>
          </>
        ),
      }
    );
  };

  return (
    <Container>
      <div className={classes.header}>
        <TextField
          className={classes.searchbar}
          variant="outlined"
          placeholder="Recherche dans le dossier"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  style={{ width: 25, height: 25 }}
                  src={require("../../Icons/search.png")}
                />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <ButtonGroup orientation="vertical" variant="contained" color="primary">
          <Button
            onClick={() => setIsAddingFolder(true)}
            startIcon={<CreateNewFolder />}
          >
            Nouveau dossier
          </Button>
          <Button
            onClick={() => setIsAddingFile(true)}
            startIcon={<CloudUpload />}
          >
            Nouveau fichier
          </Button>
        </ButtonGroup>
      </div>
      <Breadcrumbs>
        <IconButton onClick={() => setPath(["home"])}>
          <Home className={classes.homeIcon} color="primary" />
        </IconButton>
        {path.slice(1).map((e, i) => {
          return (
            <Button
              key={i}
              onClick={() => {
                setPath(path.slice(0, i + 2));
              }}
            >
              {e}
            </Button>
          );
        })}
      </Breadcrumbs>
      {loading && <CircularProgress color="primary" />}
      {content.length === 0 && !loading && (
        <Typography>Aucun fichier ou dossier pour l'instant</Typography>
      )}
      {content.flatMap((e, i) => {
        if (search && !e.name.toLowerCase().includes(search.toLowerCase())) {
          return [];
        }
        return (
          <Box className={classes.item} key={e.id}>
            <div
              onClick={() =>
                e.type === "file"
                  ? downloadFile(e)
                  : setPath((prev) => [...prev, e.name])
              }
              className={classes.itemTitle}
            >
              {e.type === "file" ? <Description /> : <FolderOpen />}
              <Typography>{e.name}</Typography>
            </div>

            <ButtonGroup>
              {e.type === "file" && (
                <IconButton onClick={() => downloadFile(e)}>
                  <CloudDownload />
                </IconButton>
              )}

              <IconButton
                onClick={() => {
                  setSelectedItem(e);
                  setNewName(e.name);
                  setIsRenaming(true);
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() =>
                  e.type === "folder" ? deleteFolder(e) : deleteFile(e)
                }
              >
                <Delete style={{ color: "tomato" }} />
              </IconButton>
            </ButtonGroup>
          </Box>
        );
      })}
      <Dialog
        fullWidth
        open={isAddingFile}
        onClose={() => setIsAddingFile(false)}
      >
        <DialogTitle>Ajouter un fichier</DialogTitle>
        <DialogContent className={classes.form}>
          <input
            id="contained-button-file"
            disabled={uploading}
            className={classes.input}
            hidden
            onChange={(e) => {
              setNewFile(e.target.files[0]);
              setNewName(e.target.files[0].name);
            }}
            type="file"
          />
          <label htmlFor="contained-button-file">
            <Button component="span" variant="contained" color="primary">
              Sélectionner un fichier
            </Button>
          </label>
          <TextField
            disabled={uploading}
            variant="outlined"
            placeholder="Nom du fichier"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={uploading || !newFile}
            onClick={() => {
              setIsAddingFile(false);
              setNewFile(null);
              setNewName("");
            }}
          >
            Annuler
          </Button>
          <Button
            disabled={uploading}
            color="primary"
            variant="contained"
            onClick={uploadFile}
          >
            {uploading ? <CircularProgress /> : "Ajouter"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        open={isAddingFolder}
        onClose={() => setIsAddingFolder(false)}
      >
        <DialogTitle>Ajouter un dossier</DialogTitle>
        <DialogContent className={classes.form}>
          <TextField
            variant="outlined"
            placeholder="Nom du dossier"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            disabled={uploading}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={uploading} onClick={() => setIsAddingFolder(false)}>
            Annuler
          </Button>
          <Button
            onClick={addFolder}
            disabled={uploading || !newName}
            color="primary"
            variant="contained"
          >
            {uploading ? <CircularProgress /> : "Ajouter"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth open={isRenaming} onClose={() => setIsRenaming(false)}>
        <DialogTitle>Renommer</DialogTitle>
        <DialogContent className={classes.form}>
          <TextField
            variant="outlined"
            placeholder="Nouveau nom"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsRenaming(false)}>Annuler</Button>
          <Button
            onClick={async () => {
              try {
                await selectedItem.ref.update({ name: newName });
                if (selectedItem.type === "folder") {
                  const { docs } = await shipRef
                    .collection("storage")
                    .where(
                      "path",
                      ">=",
                      selectedItem.path + "/" + selectedItem.name
                    )
                    .get();
                  await Promise.all(
                    docs.map(async (doc) => {
                      await doc.ref.update({
                        path: doc
                          .data()
                          .path.replace(
                            selectedItem.path + "/" + selectedItem.name,
                            selectedItem.path + "/" + newName
                          ),
                      });
                    })
                  );
                }
                setContent(
                  content.map((e) =>
                    e.id === selectedItem.id ? { ...e, name: newName } : e
                  )
                );
                setIsRenaming(false);
                enqueueSnackbar("Renommé", { variant: "success" });
              } catch (e) {
                console.error(e);
                enqueueSnackbar("Erreur lors du renommage", {
                  variant: "error",
                });
              }
            }}
            color="primary"
            variant="contained"
          >
            Renommer
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
    gap: 20,
  },
  searchbar: {
    width: "100%",
  },
  homeIcon: {
    width: 35,
    height: 35,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px",
    gap: 20,
    backgroundColor: "#FFF",
    borderRadius: 15,
    margin: "10px 0",
    boxShadow: "0 0 5px rgba(0,0,0,0.1)",

    "&:hover": {
      backgroundColor: "#F7F7F7",
    },
  },
  itemTitle: {
    display: "flex",
    gap: 10,
    cursor: "pointer",
    alignItems: "center",
    flex: 1,
    padding: "20px 0",
  },
}));
export default Storage;
