import React from "react";
import Header from "../Elements/Header";

export default function AppContainer({ firebase, children }) {
  return (
    <div>
      <Header firebase={firebase}>{children}</Header>
    </div>
  );
}
