import React from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { Field } from "react-final-form";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.dark + "!important",
  },
  formControl: {
    width: "100%",
    marginTop: 0,
    marginBottom: 8,
  },
  container: {
    marginTop: 16,
    marginBottom: 8,
  },
}));

export default function InputSelect({
  name,
  label,
  type = "text",
  variant = "outlined",
  required = false,
  classes = {},
  value,
  content = [],
  ownOnChange = () => {},
}) {
  const style = useStyles();
  return (
    <Field
      name={name}
      component="input"
      type={type}
      className={style.container}
    >
      {(props) => (
        <FormControl
          required={required}
          variant="outlined"
          classes={{ root: style.formControl }}
        >
          <InputLabel id={name} classes={{ root: classes.root }}>
            {label}
          </InputLabel>
          <Select
            variant="outlined"
            labelId={name}
            label={label}
            value={props.input.value}
            className={style.root}
            onChange={props.input.onChange}
            classes={classes}
            margin="normal"
          >
            {content.map((item) => {
              return (
                <MenuItem value={item.id} key={item.id}>
                  {item.name || item.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </Field>
  );
}
