import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";



const TopRightLogo = () => {
    
    const classes = useStyles();
    const [size, setSize] = useState(0);


    const displayWindowSize = () => {
      if (window.innerWidth > 700) {
        setSize(true);
      } else {
        setSize(false);
      }
    };
  
    useEffect(() => {
      if (window.innerWidth > 700) {
        setSize(true);
      } else {
        setSize(false);
      }
    }, [])
  
    window.addEventListener("resize", displayWindowSize);

    if(!size)return<p></p>;
    return (
        <div className={classes.anchoredLogoWrapper}>
            <svg xmlns="http://www.w3.org/2000/svg" width="132.842" height="124.5" viewBox="0 0 132.842 124.5" className={classes.anchoredLogo}>
                <g id="Logo_Appli" data-name="Logo Appli" transform="translate(0.01 0)">
                    <path id="Tracé_139" data-name="Tracé 139" d="M137.05,179.515a49.375,49.375,0,0,1,49.373-49.375h0A49.373,49.373,0,0,1,235.8,179.515h0a49.374,49.374,0,0,1-49.372,49.373h0A49.374,49.374,0,0,1,137.05,179.515Zm17.678-31.694a44.682,44.682,0,0,0-13.13,31.694h0a44.666,44.666,0,0,0,13.13,31.687h0a44.663,44.663,0,0,0,31.7,13.135h0a44.655,44.655,0,0,0,31.7-13.135h0a44.658,44.658,0,0,0,13.132-31.693h0a44.66,44.66,0,0,0-13.132-31.689h0a44.651,44.651,0,0,0-31.694-13.128h0a44.656,44.656,0,0,0-31.7,13.128Z" transform="translate(-119.281 -121.274)" fill="#ffdf99"/>
                    <path id="Tracé_140" data-name="Tracé 140" d="M68.69,120.041A58.239,58.239,0,0,1,126.938,61.8h0a58.249,58.249,0,0,1,58.248,58.248h0A58.253,58.253,0,0,1,126.944,178.3h0A58.238,58.238,0,0,1,68.7,120.054ZM88.968,82.075a53.513,53.513,0,0,0-15.727,37.966h0a53.509,53.509,0,0,0,15.727,37.965h0a53.527,53.527,0,0,0,37.964,15.73h0a53.53,53.53,0,0,0,37.968-15.73h0a53.5,53.5,0,0,0,15.724-37.965h0A53.508,53.508,0,0,0,164.9,82.075h0a53.534,53.534,0,0,0-37.968-15.728h0A53.531,53.531,0,0,0,88.968,82.075Z" transform="translate(-59.789 -61.8)" fill="#ffdf99"/>
                    <path id="Tracé_141" data-name="Tracé 141" d="M453,454.452a8.393,8.393,0,0,1,8.39-8.392h0a8.391,8.391,0,0,1,8.39,8.391h0a8.391,8.391,0,0,1-8.388,8.39h0A8.392,8.392,0,0,1,453,454.453Zm4.548,0a3.853,3.853,0,0,0,3.843,3.841h0a3.846,3.846,0,0,0,3.841-3.841h0a3.846,3.846,0,0,0-3.841-3.845h0a3.85,3.85,0,0,0-3.85,3.845Z" transform="translate(-394.243 -396.211)" fill="#ffdf99"/>
                    <path id="Tracé_142" data-name="Tracé 142" d="M395.461,577.434h-.008a22.044,22.044,0,0,1-17.384-8.183h0l3.623-2.761c2.854,3.762,8.029,6.4,14,6.4h.009a17.624,17.624,0,0,0,13.915-6.271h0l3.583,2.806a22.14,22.14,0,0,1-17.5,8.013h-.236Z" transform="translate(-329.034 -501.017)" fill="#ffdf99"/>
                    <path id="Tracé_143" data-name="Tracé 143" d="M209.651,501.457a1.3,1.3,0,0,0-1.3-1.3h-7.646a1.3,1.3,0,0,0-1.3,1.3h0a1.3,1.3,0,0,0,1.3,1.3h7.646A1.3,1.3,0,0,0,209.651,501.457Z" transform="translate(-173.551 -443.292)" fill="#ffdf99"/>
                    <path id="Tracé_144" data-name="Tracé 144" d="M513.647,193.06a1.3,1.3,0,0,0-1.3,1.3V202a1.3,1.3,0,0,0,1.3,1.3h0a1.3,1.3,0,0,0,1.3-1.3v-7.641a1.3,1.3,0,0,0-1.3-1.3Z" transform="translate(-445.894 -176.032)" fill="#ffdf99"/>
                    <path id="Tracé_145" data-name="Tracé 145" d="M354.668,230.383a1.3,1.3,0,0,0-.331,1.808l4.354,6.287a1.3,1.3,0,0,0,1.81.328h0a1.3,1.3,0,0,0,.327-1.81l-4.354-6.284a1.3,1.3,0,0,0-1.806-.33Z" transform="translate(-308.179 -208.312)" fill="#ffdf99"/>
                    <path id="Tracé_146" data-name="Tracé 146" d="M251.02,350.727a1.3,1.3,0,0,0,.37,1.8l6.384,4.2a1.3,1.3,0,0,0,1.8-.368h0a1.3,1.3,0,0,0-.37-1.8l-6.387-4.207a1.3,1.3,0,0,0-1.8.37Z" transform="translate(-218.277 -312.736)" fill="#ffdf99"/>
                    <path id="Tracé_147" data-name="Tracé 147" d="M762.53,506.147a1.3,1.3,0,0,1,1.3-1.3h7.646a1.3,1.3,0,0,1,1.3,1.3h0a1.3,1.3,0,0,1-1.3,1.3h-7.646A1.3,1.3,0,0,1,762.53,506.147Z" transform="translate(-663.618 -447.374)" fill="#ffdf99"/>
                    <path id="Tracé_148" data-name="Tracé 148" d="M639.6,235.1a1.3,1.3,0,0,1,.331,1.807l-4.355,6.28a1.3,1.3,0,0,1-1.808.33h0a1.3,1.3,0,0,1-.323-1.8l4.354-6.288a1.3,1.3,0,0,1,1.808-.326Z" transform="translate(-551.079 -212.412)" fill="#ffdf99"/>
                    <path id="Tracé_149" data-name="Tracé 149" d="M729.588,355.449a1.3,1.3,0,0,1-.371,1.8l-6.384,4.2a1.3,1.3,0,0,1-1.8-.368h0a1.305,1.305,0,0,1,.368-1.8l6.389-4.2a1.3,1.3,0,0,1,1.8.372Z" transform="translate(-627.317 -316.845)" fill="#ffdf99"/>
                    <path id="Tracé_150" data-name="Tracé 150" d="M561.775,377.843a1.3,1.3,0,0,0-1.836-.067l-11.546,10.734a1.3,1.3,0,0,0-.069,1.837h0a1.3,1.3,0,0,0,1.838.066l11.546-10.73a1.3,1.3,0,0,0,.065-1.84Z" transform="translate(-476.899 -336.482)" fill="#ffdf99"/>
                    <path id="Tracé_151" data-name="Tracé 151" d="M163.7,575.487h-.228c-7.484-.069-14.126-3.191-18.1-8.146h0l3.555-2.841c2.994,3.773,8.458,6.448,14.768,6.438h0a.377.377,0,0,1,.053,0h0c6.22,0,11.615-2.615,14.626-6.309h0l3.517,2.883c-4.029,4.9-10.7,7.969-18.192,7.976h0Z" transform="translate(-126.521 -499.286)" fill="#ffdf99"/>
                    <path id="Tracé_152" data-name="Tracé 152" d="M618.085,577.06h-.009c-7.6-.071-14.351-3.177-18.394-8.126h0l3.526-2.874c3.054,3.774,8.644,6.462,15.1,6.451h.008c6.39.01,11.935-2.615,15.011-6.324h0l3.494,2.915c-4.108,4.9-10.889,7.951-18.5,7.958Zm-18.4-8.127Z" transform="translate(-521.895 -500.643)" fill="#ffdf99"/>
                    <path id="Tracé_153" data-name="Tracé 153" d="M66.265,317.961A81.7,81.7,0,0,1,0,284.128v67.559c0,20.227,22.094,27.532,27.131,27.727h77.984c25.781-1.731,27.727-25.442,27.727-27.727V283.7A81.7,81.7,0,0,1,66.265,317.961Z" transform="translate(-0.01 -254.913)" fill="none"/>
                </g>
            </svg>

        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    anchoredLogoWrapper: {
        position: "absolute",
        top: -310,
        right: -190,
        backgroundColor: "#00638F",
        borderRadius: "50%",
        height: 450,
        width: 450,
    },
    anchoredLogo: {
        position: "absolute",
        height: 100,
        width: 100,
        bottom: 30,
        left: 120,
    },
  }));

export default TopRightLogo;