import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useCookies } from "react-cookie";
import { Grid, Paper, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ShipListItem from "../Components/ShipListItem";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 300,
    background: "rgb(17,175,211)",
    background: "#4b4b4b",
    height: "100vh",
    paddingTop: 10,
  },
  fullList: {
    width: "auto",
  },
  categorieTitle: {
    fontWeight: 400,
    fontSize: 40,
    textAlign: "left",
    color: "#fff",
    marginTop: 0,
    marginLeft: 20,
    fontFamily: "Poppins",
  },
  lineName: {
    color: "#fff",
    marginLeft: 15,
    fontWeight: 400,
    fontSize: 17,
    fontFamily: "Poppins",
    textDecoration: "none",
  },
  itemContainer: {
    paddingBottom: 29,
    marginLeft: 5,
    paddingTop: 28,
  },
  itemContainerSelected: {
    backgroundImage: "url('images/menu.png')",
    paddingBottom: 29,
    marginLeft: 5,
    width: 300,
    paddingTop: 28,
    backgroundSize: "cover",
  },
  lineName1: {
    color: "#0071BC",
    marginLeft: 15,
    fontWeight: 400,
    fontSize: 17,
    fontFamily: "Poppins",
    textDecoration: "none",
  },
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/welcome.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(0, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "50%",
    borderRadius: 25,
    backgroundColor: "#00638F",
  },
  inputSemi: {
    width: "48%",
  },
  inputsLine1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  grid: {
    height: "100vh",
    width: 350,
  },
  logoutBtn: {
    textDecoration: "none",
    color: "#00638F",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 5,
    marginTop: 25,
    "&:hover": {
      color: "#b21d1d",
    },
  },
}));

export default function TemporaryDrawer({ firebase, open, switchMenu }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });
  const [redirect, setRedirect] = useState(null);
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies();
  const history = useHistory();
  useEffect(() => {
    setState({ ...state, left: open });
  }, [open]);

  const shipsRef = firebase.db.collection("Ships");
  const [ships, setShips] = useState([]);

  const getShips = () => {
    shipsRef
      .where("armateur", "==", cookies.userToken)
      .get()
      .then((docs) => {
        let tmp = [];
        docs.forEach((doc) => {
          tmp.push({ ...doc.data(), id: doc.id });
        });
        setShips(tmp);
      });
  };
  useEffect(() => {
    getShips();
  }, []);

  const logout = async () => {
    removeCookie("userToken", { path: "/" });
    removeCookie("email", { path: "/" });

    history.replace("/login");
  };

  const sideList = (side) => (
    <Grid className={classes.grid} component={Paper} elevation={8}>
      <div className={classes.paper} style={{ width: "90%" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              marginTop: 50,
              color: "#00638F",
              fontSize: 40,
            }}
          >
            Navires
          </Typography>
          <a href="/dashboard">
            <AddCircleOutlineIcon
              size="large"
              style={{
                marginTop: 50,
                marginLeft: 10,
                width: 50,
                height: 40,
                color: "#00638F",
              }}
            ></AddCircleOutlineIcon>
          </a>
        </div>
        {/* <div style={{ display: "flex", flexDirection: "row" }}>
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              color: "#00638F",
              fontSize: 40,
              alignItems: "center",
            }}
          >
            Partenariats
          </Typography>
          <a href="/partenaire">
            <AddCircleOutlineIcon
              size="large"
              style={{
                marginTop: 3,
                marginLeft: 10,
                width: 50,
                height: 40,
                color: "#00638F",
              }}
            ></AddCircleOutlineIcon>
          </a>
        </div> */}
        <div
          style={{
            overflowY: "scroll",
            width: "100%",
            padding: 10,
            height: "70vh",
          }}
        >
          {ships.map((ship) => {
            return (
              <ShipListItem ship={ship} firebase={firebase} />
              /*  <>
                {location.pathname.includes(ship.id) ? (
                  <Link
                    to={"/ship/" + ship.id + "/cahier"}
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      style={{
                        border: "2px solid #B5B5B5",
                        backgroundColor: "#00638F",
                        marginTop: 50,
                        padding: 50,
                        paddingBottom: 10,
                        paddingTop: 10,
                        borderRadius: 50,
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        component="h3"
                        variant="h5"
                        style={{
                          fontFamily: "Poppins",
                          fontSize: 25,
                          color: "#fff",
                          textDecoration: "none",
                        }}
                      >
                        {ship.name}
                      </Typography>
                    </div>
                  </Link>
                ) : (
                  <Link
                    to={"/ship/" + ship.id + "/cahier"}
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      style={{
                        border: "2px solid #B5B5B5",
                        marginTop: 50,
                        padding: 50,
                        paddingBottom: 10,
                        paddingTop: 10,
                        borderRadius: 50,
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        component="h3"
                        variant="h5"
                        style={{
                          fontFamily: "Poppins",
                          fontSize: 25,
                          color: "#B5B5B5",
                          textDecoration: "none",
                        }}
                      >
                        {ship.name}
                      </Typography>
                    </div>
                  </Link>
                )}
              </> */
            );
          })}
        </div>
        <Link onClick={logout} className={classes.logoutBtn}>
          <ExitToAppIcon style={{ marginRight: 5 }} /> Déconnexion
        </Link>

        <Grid container>
          <Grid item xs></Grid>
          <Grid item></Grid>
        </Grid>
      </div>
    </Grid>
  );
  return (
    <>
      {redirect && <Redirect push to={redirect} />}
      {sideList("left")}
    </>
  );
}
