import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import RequestAPI from "../../Utils/API";

const SubscribeReleve = ({ firebase, shipId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubscribe = () => {
    setLoading(true);
    RequestAPI("POST", "formation", {
      ship: shipId,
    })
      .then((res) => {
        setLoading(false);
        setIsOpen(false);
        enqueueSnackbar(
          "Félicitations, vous avez désormais accès au module relevé machine !",
          {
            variant: "success",
          }
        );
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar("Désolé, une erreur s'est produite", {
          variant: "error",
        });
        setLoading(false);
      });
    setLoading(false);
    setIsOpen(false);
  };
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 20,
      }}
    >
      <Typography
        component="h3"
        variant="h5"
        style={{
          fontFamily: "Poppins",
          color: "#00638F",
          fontSize: 20,
          textAlign: "center",
        }}
      >
        Indisponible avec votre offre actuelle ; cliquez ici pour obtenir le
        relevé machine pour 5 euros de plus par mois
      </Typography>
      <Button
        onClick={() => setIsOpen(true)}
        variant="contained"
        color="primary"
      >
        Souscrire
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth>
        <DialogTitle>
          <Typography
            style={{ fontWeight: "bold", color: "#00638F", marginBottom: 0 }}
            component="h2"
          >
            Module relevé machine
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Le module relevé machine est facturé 5€ par mois.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Annuler</Button>
          <Button
            onClick={handleSubscribe}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            Souscrire
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SubscribeReleve;
