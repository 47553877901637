import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputAdornment,
  makeStyles,
  TablePagination,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { useSnackbar } from "notistack";
import { CloudDownload, Delete, Edit, Visibility } from "@material-ui/icons";
import HistoryIcon from "@material-ui/icons/History";
import { useCookies } from "react-cookie";

const Horodatage = ({ firebase, shipId, ship }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = useState("");
  const [deleteMoteur, setDeleteMoteur] = useState(false);
  const [updateMoteur, setUpdateMoteur] = useState(null);
  const [historyMoteur, setHistoryMoteur] = useState(null);
  const [upload, setUpload] = useState(false);
  const [motorName, setMotorName] = useState("");
  const [motorHours, setMotorHours] = useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const fetchEvents = () => {
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("horodatage")
      .get()
      .then((snapshot) => {
        let tmp = [];
        snapshot.forEach((doc) => {
          tmp.push({ ...doc.data(), id: doc.id, ref: doc.ref });
        });
        setEvents(tmp);
      });
  };

  const handleAdd = () => {
    if (!motorName || !motorHours) {
      enqueueSnackbar("Vous devez remplir tous les champs !");

      return;
    }
    enqueueSnackbar("Chargement...", { variant: "info" });

    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("horodatage")
      .add({
        label: motorName,
        hours: motorHours,
        lastModified: new Date(),
      })
      .then(() => {
        fetchEvents();
        enqueueSnackbar("Moteur enregistré !", { variant: "success" });
        setUpload(false);
        setMotorHours(0);
        setMotorName("");
      });
  };

  const handleHistory = () => {
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("horodatage")
      .doc(historyMoteur.id)
      .collection("historique")
      .orderBy("lastModified", "desc")
      .get()
      .then((snapshot) => {
        let tmp = [];
        snapshot.forEach((doc) => {
          tmp.push({ ...doc.data() });
        });
        setHistorys(tmp);
      });
  };

  const handleUpdate = () => {
    if (!motorName || !motorHours) {
      enqueueSnackbar("Vous devez remplir tous les champs !");

      return;
    }
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("horodatage")
      .doc(updateMoteur.id)
      .update({
        label: motorName,
        hours: motorHours,
        lastModified: new Date(),
      })
      .then(() => {
        fetchEvents();
        setUpdateMoteur(null);
        enqueueSnackbar("Moteur mis à jour !", { variant: "success" });
      });

    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("horodatage")
      .doc(updateMoteur.id)
      .collection("historique")
      .add({
        newValue: updateMoteur.hours,
        lastModified: new Date(),
        modifiedBy: ship.responsableName,
      });

    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("event_cahier")
      .where("validated", "==", false)
      .where("recurrence_unit", "==", "Heures compteur")
      .where("moteur", "==", updateMoteur.label)
      .get()
      .then((items) =>
        items.forEach((item) => {
          const date = moment()
            .add(item.data().dueBy - motorHours, "hours")
            .format("YYYY/MM/DD");
          item.ref.update({
            date: date,
          });
        })
      );
  };

  const handleDelete = () => {
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("horodatage")
      .doc(deleteMoteur.id)
      .delete()
      .then(() => {
        enqueueSnackbar("Moteur supprimé !", { variant: "success" });
        fetchEvents();
        setDeleteMoteur(null);
      })
      .catch(() => {
        enqueueSnackbar("Erreur lors de la suppression", {
          variant: "error",
        });
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [events, setEvents] = useState([]);
  const [historys, setHistorys] = useState([]);
  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    if (historyMoteur) {
      handleHistory();
    }
  }, [historyMoteur]);

  useEffect(() => {
    if (updateMoteur) {
      setMotorName(updateMoteur.label);
      setMotorHours(updateMoteur.hours);
    } else {
      setMotorName("");
      setMotorHours(0);
    }
  }, [updateMoteur]);

  const withFilters = (item) => {
    if (search == "") {
      return true;
    }
    if (item.label?.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    return false;
  };

  return (
    <Container>
      <div className={classes.SearchAndCardWrapper}>
        <TextField
          className={classes.searchbar}
          variant="outlined"
          placeholder="Recherche par nom"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  style={{ width: 25, height: 25 }}
                  src={require("../../Icons/search.png")}
                />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />

        <svg
          style={{ fontSize: 45, cursor: "pointer" }}
          class="MuiSvgIcon-root jss174"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          onClick={() => setUpload(true)}
        >
          <path
            fill="#329646"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
          ></path>
        </svg>
      </div>
      <TableContainer>
        <Table aria-label="simple table" className={classes.head}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.col}>NOM</TableCell>
              <TableCell className={classes.col}>NOMBRE D'HEURES</TableCell>
              <TableCell className={classes.col}>
                DERNIÈRE MODIFICATION
              </TableCell>
              <TableCell className={classes.col}>HISTORIQUE</TableCell>
              <TableCell className={classes.col}>MODIFIER</TableCell>
              <TableCell className={classes.col}>SUPPRIMER</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events
              .filter((item) => withFilters(item))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id} className={classes.row}>
                  <TableCell>{row.label}</TableCell>
                  <TableCell>{row.hours}</TableCell>
                  <TableCell>
                    {row.lastModified
                      ? moment(row.lastModified.toDate()).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )
                      : 0}
                  </TableCell>
                  <TableCell>
                    <HistoryIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setHistoryMoteur(row);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Edit
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setUpdateMoteur(row);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Delete
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setDeleteMoteur(row);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={events.filter((item) => withFilters(item)).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Lignes par page"
      />

      <Dialog
        open={historyMoteur}
        onClose={() => setHistoryMoteur(false)}
        fullWidth
      >
        <DialogTitle>Historique</DialogTitle>
        <DialogContent style={{ display: "flex", flexWrap: "wrap" }}>
          {historys.map((row) => (
            <div style={{ display: "flex", width: "100%" }}>
              <p style={{ width: "40%" }}>Heures du moteur : {row.newValue}</p>

              <p style={{ width: "60%" }}>
                Modifié par {row.modifiedBy} le{" "}
                {row.lastModified.seconds
                  ? moment(row.lastModified.toDate()).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )
                  : 0}
              </p>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHistoryMoteur(false)}>Fermer</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={upload} onClose={() => setUpload(false)} fullWidth>
        <DialogTitle>Ajout de moteur</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              value={motorName}
              onChange={(e) => setMotorName(e.target.value)}
              variant="outlined"
              label="Nom du moteur"
              style={{ marginBottom: 20 }}
            />
            <TextField
              value={motorHours}
              onChange={(e) => setMotorHours(e.target.value)}
              variant="outlined"
              label="Horodatage moteur"
              type="number"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUpload(false)}>Annuler</Button>

          <Button onClick={handleAdd}>Ajouter le moteur</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteMoteur}
        onClose={() => setDeleteMoteur(false)}
        fullWidth
      >
        <DialogTitle>Attention - {deleteMoteur?.label}</DialogTitle>
        <DialogContent>
          <Typography>
            Voulez vous vraiment supprimer ce moteur ? Cette action est
            irréversible
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteMoteur(false)}>Annuler</Button>

          <Button onClick={handleDelete}>Supprimer le moteur</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={updateMoteur}
        onClose={() => setUpdateMoteur(null)}
        fullWidth
      >
        <DialogTitle>Modifier un moteur</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              value={motorName}
              onChange={(e) => setMotorName(e.target.value)}
              variant="outlined"
              label="Nom du moteur"
              style={{ marginBottom: 20 }}
            />
            <TextField
              value={motorHours}
              onChange={(e) => setMotorHours(e.target.value)}
              variant="outlined"
              label="Horodatage moteur"
              type="number"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setUpdateMoteur(null);
              setMotorName("");
              setMotorName(0);
            }}
          >
            Annuler
          </Button>

          <Button onClick={handleUpdate}>Modifier le moteur</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  col: {
    color: "#00638F",
    borderBottomWidth: 0,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#FFF",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#DCEFF6",
    },
  },
  searchbar: {
    display: "flex",
    borderRadius: 50,
    width: "60%",
    margin: "10px auto",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "no-wrap",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    padding: "0 15px",
    textAlign: "center",
  },
  SearchAndCardWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export default Horodatage;
