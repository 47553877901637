import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FinalForm from "../Utils/FinalForm";
import InputText from "../Inputs/InputText";
import RequestAPI from "../Utils/API";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
import { Redirect, useHistory, Link, useParams } from "react-router-dom";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useStripe, useElements, IbanElement } from "@stripe/react-stripe-js";
import { CircularProgress } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/welcome.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "50%",
    borderRadius: 25,
    backgroundColor: "#00638F",
  },
  inputSemi: {
    width: "48%",
  },
  inputsLine1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  inputDesc: {},
}));

export default function Iban({ firebase }) {
  const classes = useStyles();
  const [cookies, setCookie, removeCookie] = useCookies();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const shipsRef = firebase.db.collection("Ships");
  const [ships, setShips] = useState([]);
  const [loading, setLoading] = useState(false);
  const [setupSecret, setSetupSecret] = useState("");
  const [titulaire, setTitulaire] = useState("");
  const [email, setEmail] = useState("");
  const { shipId } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const handleSubscribe = () => {
    RequestAPI("POST", "releve", {
      ship: shipId,
    })
      .then((res) => {
        enqueueSnackbar(
          "Félicitations, vous avez désormais accès au module relevé machine !",
          {
            variant: "success",
          }
        );
        removeCookie("releve", { path: "/" });
      })
      .catch((error) => {
        console.log(error);

        enqueueSnackbar("Désolé, une erreur s'est produite", {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    RequestAPI("POST", "setup", { token: cookies.userToken })
      .then((res) => {
        setSetupSecret(res.data.secret);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    const iban = elements.getElement(IbanElement);

    const result = await stripe.confirmSepaDebitSetup(setupSecret, {
      payment_method: {
        sepa_debit: iban,
        billing_details: {
          name: values.titulaire,
          email: values.email,
        },
      },
    });

    if (result.error) {
      enqueueSnackbar(result.error.message, {
        variant: "error",
      });
      setLoading(false);
      return;
    } else {
      console.log(result);

      RequestAPI("POST", "subscription", {
        token: cookies.userToken,
        ship: shipId,
        setupIntent: result.setupIntent.id,
        plan: cookies.plan,
        formation: cookies,
      })
        .then((res) => {
          if (cookies.releve) {
            handleSubscribe();
          }
          removeCookie("formation", { path: "/" });
          removeCookie("releve", { path: "/" });
          enqueueSnackbar("Moyen de paiement ajouté!", { variant: "success" });
          enqueueSnackbar("Ajoutez un marin", {
            variant: "info",
          });
          enqueueSnackbar("Dernière étape !", {
            variant: "info",
          });
          setLoading(false);
          history.push(`/${shipId}/sailors`);
        })
        .catch((err) => {
          enqueueSnackbar("Erreur lors de l'enregistrement", {
            variant: "error",
          });
          setLoading(false);
        });
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={8}>
        <Typography
          component="h1"
          variant="h1"
          style={{
            fontFamily: "MostraThree",
            fontSize: 50,
            color: "#00638F",
            textDecoration: "none",
            margin: "50px 0",
            marginLeft: "15%",
          }}
        >
          MAINT & SEA
        </Typography>

        <div
          style={{
            padding: "5%",
          }}
        >
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              color: "#00638F",
              fontSize: 40,
              textAlign: "center",
            }}
          >
            Ajouter un moyen de paiement
          </Typography>

          <FinalForm onSubmit={handleSubmit}>
            <InputText
              name="titulaire"
              label="Titulaire du compte"
              required
            ></InputText>
            <InputText
              name="email"
              label="Email du titulaire"
              required
            ></InputText>{" "}
            <Typography
              style={{
                fontFamily: "Poppins",
                color: "#00638F",
                fontSize: 18,
                marginBottom: 0,
              }}
            >
              IBAN
            </Typography>
            <div
              style={{
                margin: " auto",
                borderRadius: 50,
                borderWidth: 1,
                borderColor: "#00638F",
                borderStyle: "solid",
                padding: 15,
              }}
            >
              <IbanElement
                options={{
                  style: { base: { fontSize: "18px" } },
                  hidePostalCode: true,
                  supportedCountries: ["SEPA"],
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              {loading ? (
                <CircularProgress style={{ marginTop: 15 }} />
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={!setupSecret}
                >
                  Valider
                </Button>
              )}
            </div>
          </FinalForm>
        </div>
      </Grid>
    </Grid>
  );
}
