import React from "react";
import { useCookies } from "react-cookie";
import { Redirect, Route } from "react-router";

const PrivateRoute = ({ children, path, ...props }) => {
  const [cookies, setCookie] = useCookies();
  return !!cookies.userToken ? (
    <Route path={path} {...props}>
      {children}
    </Route>
  ) : (
    <Redirect to="/login" />
  );
};

export default PrivateRoute;
