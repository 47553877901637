import React, { useEffect } from "react";
import { Form, Field } from "react-final-form";

export default function FinalForm({
  onSubmit,
  initialValues,
  validate = () => {},
  style,
  children
}) {
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          style={{ width: "100%" }}
          className={style}
        >
          {children}
        </form>
      )}
    />
  );
}
