import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FinalForm from "../Utils/FinalForm";
import InputText from "../Inputs/InputText";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import moment, { relativeTimeRounding } from "moment";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { Chip, CircularProgress } from "@material-ui/core";
import Compressor from "compressorjs";
import { ArrowBack } from "@material-ui/icons";
import { set } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/welcome.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "50%",
    borderRadius: 25,
    backgroundColor: "#00638F",
  },
  label: {
    marginTop: 20,
    marginLeft: 20,
    fontSize: 22,
    color: "rgb(134 134 134)",
    marginBottom: "-12px",
  },
  fileInput: {
    borderRadius: 25,
    backgroundColor: "#00638F",
    width: "50%",
    color: "#ffffff",
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column !important",
    flexWrap: "wrap",
    color: "#ffffff",
  },
}));

export default function UpdateHydrocarbure({ firebase }) {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { shipId, eventId } = useParams();
  const [date, setDate] = useState();
  const [hydrocarbures, setHydrocarbures] = useState([]);
  const [ports, setPorts] = useState([]);
  const [moteurs, setMoteurs] = useState([]);
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState("");
  const [deleteFile, setDeleteFile] = useState(false);
  const [hydrocarbure, setHydrocarbure] = useState("");
  const [port, setPort] = useState("");
  const [moteur, setMoteur] = useState("");
  const [horodatage, setHorodatage] = useState("");
  const [quantity, setQuantity] = useState("");
  const [quantityUnit, setQuantityUnit] = useState("");
  const [quantityDown, setQuantityDown] = useState("");

  const [quantityDownUnit, setQuantityDownUnit] = useState("");
  const [comment, setComment] = useState("");
  const [price, setPrice] = useState("");

  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState();
  const [photo, setPhoto] = useState("");
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (values) => {
    setSubmitting(true);
    const heuresCompteur = parseInt(values.horodatage);
    console.log(values);
    let _url = deleteFile ? null : url;

    if (file) {
      const storageRef = firebase.storage.ref();
      const fileRef = storageRef.child(
        `ship/${shipId}/maintenance/${Date.now()}/${file.name}`
      );
      await fileRef.put(file);
      _url = await fileRef.getDownloadURL();
    }

    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("event_hydro")
      .doc(eventId)
      .update({
        date: moment(values.date).format("YYYY/MM/DD"),
        hydrocarbure: hydrocarbure || "",
        port: port || "",
        moteur: moteur?.value || "",
        dueBy: heuresCompteur || "",
        quantity: values?.quantity || "",
        quantityUnit: quantityUnit || "",
        unitPrice: values?.price || "",
        descriptionDetail: values?.commentaire || "",
        quantityDown: values?.quantityDown || "",
        quantityDownUnit: quantityDownUnit || "",
        image: photo,
        lastModified: new Date(),
        modifiedBy: "armateur",
        file: _url || null,
      })
      .then(() => {
        enqueueSnackbar("Évènement modifié !", { variant: "success" });
        setSubmitting(false);
        history.push("/ship/" + shipId + "/hydrocarbure");
      })
      .catch((err) => {
        setSubmitting(false);
        enqueueSnackbar("Erreur lors de la modification de l'évènement", {
          variant: "error",
        });
        console.log(err);
      });
  };

  const compressFile = (file, quality) => {
    if (quality < 0) {
      enqueueSnackbar("Fichier trop volumineux !", { variant: "error" });
      return;
    }
    const compressor = new Compressor(file, {
      quality: quality,
      convertSize: 1000000,
      maxHeight: 1000,
      maxWidth: 1000,
      success(result) {
        const reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onloadend = function () {
          var base64data = reader.result;
          if (base64data.length * (3 / 4) < 1000000) {
            setPhoto(base64data);
          } else {
            compressFile(result, compressor.options.quality - 0.1);
          }
        };
      },
      error() {
        enqueueSnackbar("Échec de l'envoi du fichier  !", { variant: "error" });
      },
    });
  };

  const handleFile = (event) => {
    console.log(event.target.files);
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    compressFile(file, 0.8);
  };

  useEffect(() => {
    firebase.db
      .collection("Hydrocarbures")
      .orderBy("name")
      .get()
      .then((snapshot) => {
        let temp = [];

        snapshot.forEach((item) => {
          temp.push(item.data().name);
        });
        setHydrocarbures(temp);
      });

    firebase.db
      .collection("Ports")
      .orderBy("name")
      .get()
      .then((snapshot) => {
        let temp = [];

        snapshot.forEach((item) => {
          temp.push(item.data().name);
        });
        setPorts(temp);
      });

    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("horodatage")
      .orderBy("label")
      .get()
      .then((snapshot) => {
        let temp = [];

        snapshot.forEach((item) => {
          temp.push(item.data().label);
        });
        setMoteurs(temp);
      });

    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("event_hydro")
      .doc(eventId)
      .get()
      .then((doc) => {
        setDate(moment(doc.data().date).format("YYYY-MM-DD"));
        setHydrocarbure(doc.data().hydrocarbure);
        setMoteur(doc.data().moteur);
        setPort(doc.data().port);
        setQuantity(doc.data().quantity);
        setQuantityUnit(doc.data().quantityUnit);
        setQuantityDownUnit(doc.data().quantityDownUnit);
        setQuantityDown(doc.data().quantityDown);
        setComment(doc.data().descriptionDetail);
        // setRecurrence(doc.data().recurrence);
        // setRecurrenceUnit(doc.data().recurrence_unit);
        setHorodatage(doc.data().dueBy);
        setPrice(doc.data().unitPrice);
        setPhoto(doc.data().image);
        setValidated(doc.data().validated);
        setUrl(doc.data().file);
        setLoading(false);
      });
  }, []);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={8}>
        <Typography
          component="h1"
          variant="h1"
          style={{
            fontFamily: "MostraThree",
            fontSize: 50,
            color: "#00638F",
            textDecoration: "none",
            textAlign: "center",
            margin: "50px auto",
          }}
        >
          MAINT & SEA
        </Typography>
        <Button
          color="primary"
          variant="outlined"
          startIcon={<ArrowBack color="primary" />}
          className={classes.backButton}
          onClick={() => history.goBack()}
        >
          Retour
        </Button>
        <div
          style={{
            padding: "5%",
          }}
        >
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              marginTop: 50,
              color: "#00638F",
              fontSize: 40,
              textAlign: "center",
            }}
          >
            Modifier un évènement hydrocarbure
          </Typography>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress color="primary" style={{ margin: "auto" }} />
            </div>
          ) : (
            <FinalForm
              onSubmit={handleSubmit}
              style={classes.form}
              initialValues={{
                date: date,
                commentaire: comment,
                // recurrence,
                quantity,
                horodatage,
                price,
                quantityDown,
              }}
            >
              <p className={classes.label}>Date</p>
              <InputText name="date" required type="date"></InputText>

              <p className={classes.label}>Hydrocarbure</p>
              <Autocomplete
                options={hydrocarbures}
                filterSelectedOptions
                autoHighlight
                handleHomeEndKeys
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    style={{ marginTop: 15 }}
                  />
                )}
                onChange={(event, newValue) => {
                  setHydrocarbure(newValue);
                }}
                value={hydrocarbure}
              />

              {!!hydrocarbure && (
                <>
                  <p className={classes.label}>Moteur</p>
                  <Autocomplete
                    options={moteurs}
                    filterSelectedOptions
                    autoHighlight
                    handleHomeEndKeys
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        style={{ marginTop: 15 }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setMoteur(newValue);
                    }}
                    value={moteur}
                  />

                  <p className={classes.label}>Port</p>
                  <Autocomplete
                    options={ports}
                    filterSelectedOptions
                    autoHighlight
                    handleHomeEndKeys
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        style={{ marginTop: 15 }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setPort(newValue);
                    }}
                    value={port}
                  />
                  <p className={classes.label}>Quantité embarquée</p>
                  <InputText name="quantity" type="number"></InputText>
                  <Autocomplete
                    options={["Tonnes", "Litres"]}
                    filterSelectedOptions
                    autoHighlight
                    handleHomeEndKeys
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        style={{ marginTop: 15 }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setQuantityUnit(newValue);
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setQuantityUnit(newValue.inputValue);
                      } else {
                        setQuantityUnit(newValue);
                      }
                    }}
                    value={quantityUnit}
                  />
                  <p className={classes.label}>Quantité débarquée</p>
                  <InputText name="quantityDown" type="number"></InputText>
                  <Autocomplete
                    options={["Tonnes", "Litres"]}
                    filterSelectedOptions
                    autoHighlight
                    handleHomeEndKeys
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        style={{ marginTop: 15 }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setQuantityDownUnit(newValue);
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setQuantityDownUnit(newValue.inputValue);
                      } else {
                        setQuantityDownUnit(newValue);
                      }
                    }}
                    value={quantityDownUnit}
                  />
                  <>
                    <p className={classes.label}>Prix (en €)</p>
                    <InputText name="price" type="number"></InputText>
                  </>

                  <>
                    <p className={classes.label}>Commentaire</p>
                    <InputText name="commentaire" multiline></InputText>

                    {!!photo && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                        className={classes.btnWrapper}
                      >
                        <img
                          src={photo}
                          style={{
                            maxWidth: "80%",
                            maxHeight: "80%",
                            objectFit: "contain",
                            marginTop: 10,
                          }}
                        />
                      </div>
                    )}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                      className={classes.btnWrapper}
                    >
                      {url && (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          onClick={() => {
                            window.open(url, "_blank");
                          }}
                        >
                          Télécharger le fichier
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        className={classes.submit}
                        onChange={(e) => setFile(e.target.files[0])}
                      >
                        {file || url ? "Modifier le" : "Ajouter un"} fichier
                        <input type="file" hidden accept="*" />
                      </Button>
                      {file && (
                        <p
                          style={{
                            color: "#00638F",
                            fontSize: 20,
                            marginTop: 20,
                          }}
                        >
                          {file.name}
                        </p>
                      )}
                      {(file || url) && (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          onClick={() => {
                            setFile(null);
                            setDeleteFile((prev) => !prev);
                          }}
                        >
                          Supprimer le fichier{" "}
                          {deleteFile && "(actif à l'enregistrement)"}
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        className={classes.submit}
                        onChange={handleFile}
                      >
                        {photo ? "Modifier la" : "Ajouter une"} photo
                        <input type="file" hidden accept="image/*" />
                      </Button>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={submitting}
                      >
                        {submitting ? (
                          <CircularProgress />
                        ) : (
                          "Enregistrer les modifications"
                        )}
                      </Button>
                    </div>
                  </>
                </>
              )}
            </FinalForm>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
