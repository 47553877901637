import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FinalForm from "../Utils/FinalForm";
import InputText from "../Inputs/InputText";
import RequestAPI from "../Utils/API";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { Chip } from "@material-ui/core";
import InputSelect from "../Inputs/InputSelect";
import Compressor from "compressorjs";
import { ArrowBack } from "@material-ui/icons";
import { set } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/welcome.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "50%",
    borderRadius: 25,
    backgroundColor: "#00638F",
  },
  label: {
    marginTop: 20,
    marginLeft: 20,
    fontSize: 22,
    color: "rgb(134 134 134)",
    marginBottom: "-12px",
  },
  fileInput: {
    borderRadius: 25,
    backgroundColor: "#00638F",
    width: "50%",
    color: "#ffffff",
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column !important",
    flexWrap: "wrap",
    color: "#ffffff",
  },
  backButton: {
    marginLeft: 20,
    marginTop: 20,
  },
}));

const filter = createFilterOptions();

export default function AddMaintenance({ firebase }) {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { shipId } = useParams();
  const [file, setFile] = useState(null);
  const [types, setTypes] = useState([]);
  const [titles, setTitles] = useState([]);
  const [availableTitles, setAvailablesTitles] = useState([]);
  const [baseDescriptions, setBaseDescriptions] = useState([]);
  const [availableDescriptions, setAvailableDescriptions] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [type, setType] = useState();
  const [title, setTitle] = useState();
  const [recurrence_unit, setRecurrenceUnit] = useState("");
  const [photo, setPhoto] = useState("");
  const [validated, setValidated] = useState({
    value: false,
    label: "En cours",
  });
  const [submitting, setSubmitting] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const handleSubmit = async (values) => {
    setSubmitting(true);
    let url = "";
    if (file) {
      const storageRef = firebase.storage.ref();
      const fileRef = storageRef.child(
        `ship/${shipId}/cahier/${Date.now()}/${file.name}`
      );
      await fileRef.put(file);
      url = await fileRef.getDownloadURL();
    }

    console.log(values.date);
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("event_maintenance")
      .add({
        date: moment(values.date).format("YYYY/MM/DD"),
        type: type?.value || "",

        title: title?.value || "",
        descriptions: descriptions || [],
        descriptionDetail: values?.commentaire || "",
        recurrence: values?.recurrence || "",
        recurrence_unit: recurrence_unit || "",
        validated: validated.value,
        image: photo,
        lastModified: new Date(),
        modifiedBy: "armateur",
        file: url,
      })
      .then(() => {
        enqueueSnackbar("Évènement créé !", { variant: "success" });
        if (validated.value) {
          handleConfirm({
            date: moment(values.date).format("YYYY/MM/DD"),
            type: type?.value || "",

            title: title?.value || "",
            descriptions: descriptions || [],
            descriptionDetail: values?.commentaire || "",
            recurrence: values?.recurrence || "",
            recurrence_unit: recurrence_unit || "",
            validated: validated.value,
            image: photo,
            lastModified: new Date(),
            modifiedBy: "armateur",
            file: url,
          });
        }
        setSubmitting(false);
        history.push("/ship/" + shipId + "/maintenance");
      })
      .catch((err) => {
        enqueueSnackbar("Erreur lors de la création de l'évènement", {
          variant: "error",
        });
        setSubmitting(false);
        console.log(err);
      });
  };

  const compressFile = (file, quality) => {
    if (quality < 0) {
      enqueueSnackbar("Fichier trop volumineux !", { variant: "error" });
      return;
    }
    const compressor = new Compressor(file, {
      quality: quality,
      convertSize: 1000000,
      maxHeight: 1000,
      maxWidth: 1000,
      success(result) {
        const reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onloadend = function () {
          var base64data = reader.result;
          if (base64data.length * (3 / 4) < 1000000) {
            setPhoto(base64data);
          } else {
            compressFile(result, compressor.options.quality - 0.1);
          }
        };
      },
      error() {
        enqueueSnackbar("Échec de l'envoi du fichier  !", { variant: "error" });
      },
    });
  };

  const handleFile = (event) => {
    console.log(event.target.files);
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    compressFile(file, 0.8);
  };

  const handleConfirm = async (values) => {
    let unit;
    if (values.recurrence_unit === "Mois") {
      unit = "months";
    } else if (values.recurrence_unit === "Semaines") {
      unit = "weeks";
    } else if (values.recurrence_unit === "Année") {
      unit = "years";
    } else if (values.recurrence_unit === "Jours") {
      unit = "days";
    } else {
      unit = false;
    }

    if (unit && values.recurrence) {
      firebase.db
        .collection("Ships")
        .doc(shipId)
        .collection("event_maintenance")
        .add({
          ...values,
          validated: false,
          date: moment(values.date)
            .add(unit, values.recurrence)
            .format("YYYY/MM/DD"),
        });
    }
  };

  useEffect(() => {
    (async () => {
      const ship = await firebase.db.collection("Ships").doc(shipId).get();
      const shipData = ship.data();
      let shipLength = ["tous"];
      if (shipData.length) {
        const length = parseFloat(shipData.length);
        console.log(length, " length");
        if (!isNaN(length)) {
          if (length <= 12) {
            shipLength.push("Jusqu'à 12m");
          } else if (length <= 24.95) {
            shipLength.push("Entre 12m et 24,95m");
          } else {
            shipLength.push("25m et plus");
          }
        }
      }
      console.log(shipLength);

      firebase.db
        .collection("TypesReglementaire")
        .where("size", "in", shipLength)
        .orderBy("label")
        .get()
        .then((snapshot) => {
          let temp = [];

          snapshot.forEach((item) => {
            if (["tous", shipData.activityType].includes(item.data().type)) {
              temp.push({
                label: item.data().label,
                value: item.data().label,
              });
            }
          });
          setTypes(temp);
        });
      firebase.db
        .collection("TitlesReglementaire")
        .where("size", "in", shipLength)
        .orderBy("label")
        .get()
        .then((snapshot) => {
          let temp = [];
          snapshot.forEach((item) => {
            if (["tous", shipData.activityType].includes(item.data().type)) {
              temp.push({
                label: item.data().label,
                value: item.data().label,
                category: item.data().category,
                type: item.data().type,
                taille: item.data().taille,
              });
            }
          });
          setTitles(temp);
        });
      firebase.db
        .collection("DescriptionsReglementaire")
        .where("size", "in", shipLength)
        .orderBy("label")
        .get()
        .then((snapshot) => {
          let temp = [];
          snapshot.forEach((item) => {
            if (["tous", shipData.activityType].includes(item.data().type)) {
              temp.push({
                label: item.data().label,
                value: item.data().label,
                category: item.data().category,
              });
            }
          });
          setBaseDescriptions(temp);
        });
    })();
  }, []);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={8}>
        <Typography
          component="h1"
          variant="h1"
          style={{
            fontFamily: "MostraThree",
            fontSize: 50,
            color: "#00638F",
            textDecoration: "none",
            textAlign: "center",
            margin: "50px auto",
          }}
        >
          MAINT & SEA
        </Typography>
        <Button
          color="primary"
          variant="outlined"
          startIcon={<ArrowBack color="primary" />}
          className={classes.backButton}
          onClick={() => history.goBack()}
        >
          Retour
        </Button>
        <div
          style={{
            padding: "5%",
          }}
        >
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              marginTop: 20,
              color: "#00638F",
              fontSize: 40,
              textAlign: "center",
            }}
          >
            Ajouter un évènement <br />
            Maintenance réglementaire
          </Typography>
          <FinalForm
            onSubmit={handleSubmit}
            style={classes.form}
            initialValues={{
              date:
                new URLSearchParams(location.search).get("date") ||
                moment().format("YYYY-MM-DD"),
            }}
          >
            <p className={classes.label}>Date</p>
            <InputText name="date" required type="date"></InputText>
            <p className={classes.label}>Statut</p>

            <Autocomplete
              options={[
                {
                  value: false,
                  label: "En cours",
                },
                {
                  value: true,
                  label: "Terminé",
                },
              ]}
              filterSelectedOptions
              autoHighlight
              handleHomeEndKeys
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  style={{ marginTop: 15 }}
                />
              )}
              onChange={(event, newValue) => {
                setValidated(newValue);
              }}
              value={validated}
            />
            <p className={classes.label}>Poste</p>
            <Autocomplete
              options={types}
              filterSelectedOptions
              autoHighlight
              handleHomeEndKeys
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  style={{ marginTop: 15 }}
                />
              )}
              onChange={(event, newValue) => {
                setType(newValue);
                setTitle(null);
                if (descriptions.length > 0) {
                  setDescriptions([]);
                }
                if (!!newValue)
                  setAvailablesTitles(
                    titles.filter((item) => item.category === newValue.value)
                  );
              }}
              value={type}
            />

            {!!type && (
              <>
                <p className={classes.label}>Action</p>
                <Autocomplete
                  options={availableTitles}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      style={{ marginTop: 15 }}
                    />
                  )}
                  freeSolo
                  selectOnFocus
                  clearOnBlur
                  autoHighlight
                  handleHomeEndKeys
                  filterSelectedOptions
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== "") {
                      filtered.push({
                        inputValue: params.inputValue,
                        label: params.inputValue,
                      });
                    }

                    return filtered;
                  }}
                  value={title}
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      setTitle({
                        label: newValue,
                        value: newValue,
                      });
                      if (!!newValue)
                        setAvailableDescriptions(
                          baseDescriptions.filter(
                            (item) => item.category === newValue
                          )
                        );
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setTitle({
                        label: newValue.inputValue,
                        value: newValue.inputValue,
                      });
                      if (!!newValue)
                        setAvailableDescriptions(
                          baseDescriptions.filter(
                            (item) => item.category === newValue.inputValue
                          )
                        );
                    } else {
                      setTitle(newValue);
                      if (!!newValue)
                        setAvailableDescriptions(
                          baseDescriptions.filter(
                            (item) => item.category === newValue.value
                          )
                        );
                    }
                  }}
                />
                {!!title && (
                  <>
                    <p className={classes.label}>Détail de l'action</p>
                    <Autocomplete
                      options={availableDescriptions}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ marginTop: 15 }}
                        />
                      )}
                      freeSolo
                      selectOnFocus
                      autoHighlight
                      handleHomeEndKeys
                      clearOnBlur
                      multiple
                      disableCloseOnSelect
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        console.log(params);
                        // Suggest the creation of a new value
                        if (params.inputValue !== "") {
                          filtered.push({
                            value: params.inputValue,
                            label: params.inputValue,
                          });
                        }

                        return filtered;
                      }}
                      value={descriptions}
                      onChange={(event, newValue) => {
                        setDescriptions(newValue);
                      }}
                      renderTags={(descriptions, getTagProps) =>
                        descriptions.map((description, index) => (
                          <Chip
                            label={description.label}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />

                    <p className={classes.label}>Commentaire</p>
                    <InputText name="commentaire" multiline></InputText>

                    <p className={classes.label}>Périodicité</p>
                    <InputText name="recurrence" type="number"></InputText>
                    <Autocomplete
                      options={["Jours", "Mois", "Semaines", "Année"]}
                      filterSelectedOptions
                      autoHighlight
                      handleHomeEndKeys
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ marginTop: 15 }}
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setRecurrenceUnit(newValue);
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setRecurrenceUnit(newValue.inputValue);
                        } else {
                          setRecurrenceUnit(newValue);
                        }
                      }}
                      value={recurrence_unit}
                    />
                    {!!photo && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                        className={classes.btnWrapper}
                      >
                        <img
                          src={photo}
                          style={{
                            maxWidth: "80%",
                            maxHeight: "80%",
                            objectFit: "contain",
                            marginTop: 10,
                          }}
                        />
                      </div>
                    )}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                      className={classes.btnWrapper}
                    >
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        className={classes.submit}
                        onChange={handleFile}
                      >
                        {photo ? "Modifier la" : "Ajouter une"} photo
                        <input type="file" hidden accept="image/*" />
                      </Button>
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        className={classes.submit}
                        onChange={(e) => setFile(e.target.files[0])}
                      >
                        {file ? "Modifier le" : "Ajouter un"} fichier
                        <input type="file" hidden accept="*" />
                      </Button>
                      {file && (
                        <p
                          style={{
                            color: "#00638F",
                            fontSize: 20,
                            marginTop: 20,
                          }}
                        >
                          {file.name}
                        </p>
                      )}
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        Créer
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
          </FinalForm>
        </div>
      </Grid>
    </Grid>
  );
}
