import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FinalForm from "../Utils/FinalForm";
import InputText from "../Inputs/InputText";
import RequestAPI from "../Utils/API";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
import { Redirect, useHistory, Link, useParams } from "react-router-dom";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Card, Checkbox, FormControlLabel } from "@material-ui/core";
import {
  CheckCircleOutline,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/welcome.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: "90%",
    margin: theme.spacing(3, 1),
    borderRadius: 25,
    backgroundColor: "#00638F",
    alignSelf: "flex-end",
  },
  inputSemi: {
    width: "48%",
  },
  inputsLine1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  inputDesc: {},
}));

export default function Plan({ firebase }) {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const shipsRef = firebase.db.collection("Ships");
  const [ships, setShips] = useState([]);
  const [formule, setFormule] = useState("");
  const [checked, setChecked] = useState(false);
  const [formation, setFormation] = useState(false);
  const { shipId } = useParams();

  const getShips = () => {
    shipsRef
      .where("armateur", "==", cookies.userToken)
      .get()
      .then((docs) => {
        let tmp = [];
        docs.forEach((doc) => {
          tmp.push({ ...doc.data(), id: doc.id });
        });
        console.log(tmp);
        setShips(tmp);
      });
  };
  useEffect(() => {
    getShips();
  }, []);

  const handleSubmit = (plan) => {
    setCookie("plan", plan);
    setCookie("releve", checked);
    setCookie("formule", formule);
    enqueueSnackbar("Plan selectionné !", { variant: "success" });
    enqueueSnackbar("Ajoutez maintenant un moyen de paiement", {
      variant: "info",
    });
    history.push(`/${shipId}/payment`);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={8}>
        <Typography
          component="h1"
          variant="h1"
          style={{
            fontFamily: "MostraThree",
            fontSize: 50,
            color: "#00638F",
            textDecoration: "none",
            margin: "50px 0",
            marginLeft: "15%",
          }}
        >
          MAINT & SEA
        </Typography>
        <div
          style={{
            padding: "5%",
          }}
        >
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              color: "#00638F",
              fontSize: 40,
              textAlign: "center",
            }}
          >
            Choisir une formule
          </Typography>
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              color: "#00638F",
              fontSize: 20,
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            1 mois d'essai offert <span style={{ width: 7, height: 3 }}> </span>
            <svg
              id="cis-gift"
              viewBox="0 0 512 512"
              width="25px"
              height="25px"
              style={{ marginTop: "-5px" }}
            >
              <path
                fill="#13a849"
                d="M456,128H379.5A76.952,76.952,0,0,0,256,38.233,76.955,76.955,0,0,0,132.5,128H56a24,24,0,0,0-24,24v80H480V152A24,24,0,0,0,456,128ZM312,56a35,35,0,0,1,0,70H277V91A35.04,35.04,0,0,1,312,56ZM200,56a35.04,35.04,0,0,1,35,35v35H200a35,35,0,0,1,0-70Z"
                class="ci-primary"
              ></path>
              <path
                fill="#13a849"
                d="M288,480H456a24,24,0,0,0,24-24V288H288Z"
                class="ci-primary"
              ></path>
              <path
                fill="#13a849"
                d="M32,456a24,24,0,0,0,24,24H224V288H32Z"
                class="ci-primary"
              ></path>
            </svg>
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "stretch",
              flexWrap: "wrap",
            }}
          >
            <Card
              style={{
                width: "30%",
                minWidth: 220,
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "column",
                padding: 10,
                marginTop: 20,
              }}
            >
              <Typography
                component="h4"
                variant="h5"
                style={{
                  fontFamily: "Poppins",
                  color: "#00638F",
                  fontSize: 20,
                  textAlign: "center",
                }}
              >
                Formule P'tit Mousse
              </Typography>

              <Typography
                component="h5"
                variant="h5"
                style={{
                  fontFamily: "Poppins",
                  color: "#00638F",
                  fontSize: 30,
                  textAlign: "center",
                  marginTop: 20,
                }}
              >
                25€/mois
              </Typography>

              <p
                style={{
                  flexGrow: 1,
                  fontFamily: "Poppins",
                  color: "#00638F",
                  fontSize: 15,
                  textAlign: "center",
                  marginTop: 5,
                  marginBottom: 10,
                }}
              >
                Accès aux modules "Maintenance réglementaire" et "Informations
                et service"
              </p>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => handleSubmit("price_1IwlTNIDhJTsGLwoAeoYyT5t")}
              >
                Choisir
              </Button>
            </Card>
            <Card
              style={{
                width: "30%",
                minWidth: 220,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: 10,
                marginTop: 20,
              }}
            >
              <Typography
                component="h4"
                variant="h5"
                style={{
                  fontFamily: "Poppins",
                  color: "#00638F",
                  fontSize: 20,
                  textAlign: "center",
                }}
              >
                Formule Bouchon gras
              </Typography>

              <Typography
                component="h5"
                variant="h5"
                style={{
                  fontFamily: "Poppins",
                  color: "#00638F",
                  fontSize: 30,
                  textAlign: "center",
                  marginTop: 20,
                }}
              >
                35€/mois
              </Typography>

              <p
                style={{
                  flexGrow: 1,
                  fontFamily: "Poppins",
                  color: "#00638F",
                  fontSize: 15,
                  textAlign: "center",
                  marginTop: 5,
                  marginBottom: 10,
                }}
              >
                Accès aux modules "Maintenance réglementaire", "Cahier machine"
                et "Informations et service"
              </p>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => handleSubmit("price_1IwlTIIDhJTsGLwo1UK8baAJ")}
              >
                Choisir
              </Button>
            </Card>
            <Card
              style={{
                width: "30%",
                minWidth: 220,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: 10,
                marginTop: 20,
              }}
            >
              <Typography
                component="h4"
                variant="h5"
                style={{
                  fontFamily: "Poppins",
                  color: "#00638F",
                  fontSize: 20,
                  textAlign: "center",
                }}
              >
                Formule Captain
              </Typography>

              <Typography
                component="h5"
                variant="h5"
                style={{
                  fontFamily: "Poppins",
                  color: "#00638F",
                  fontSize: 30,
                  textAlign: "center",
                  marginTop: 20,
                }}
              >
                55€/mois
              </Typography>

              <p
                style={{
                  fontFamily: "Poppins",
                  color: "#00638F",
                  fontSize: 15,
                  textAlign: "center",
                  marginTop: 5,
                  marginBottom: 10,
                }}
              >
                Accès aux modules "Maintenance réglementaire", "Cahier machine",
                "Calendrier de suivi et rappel" et "Informations et service"
              </p>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => handleSubmit("price_1IwlTAIDhJTsGLwo43SEPNCM")}
              >
                Choisir
              </Button>
            </Card>
          </div>

          <div
            style={{
              padding: "3%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              component="h3"
              variant="h5"
              style={{
                fontFamily: "Poppins",
                color: "#00638F",
                fontSize: 32,
                marginTop: 20,
                // textAlign: "center",
              }}
            >
              Options
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => {
                    setChecked(!checked);
                  }}
                  name="checkedB"
                  color="primary"
                  style={{ color: "#00638F" }}
                  size="medium"
                  icon={<RadioButtonUnchecked />}
                  checkedIcon={<CheckCircleOutline />}
                />
              }
              label={
                <Typography
                  component="p"
                  variant="p"
                  style={{
                    fontFamily: "Poppins",
                    // color: "#00638F",
                    fontSize: 16,
                  }}
                >
                  Relevé machine (5,00€/mois)
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formation}
                  onChange={() => {
                    setFormation(!formation);
                  }}
                  name="checkedB"
                  color="primary"
                  style={{ color: "#00638F" }}
                  size="medium"
                  icon={<RadioButtonUnchecked />}
                  checkedIcon={<CheckCircleOutline />}
                />
              }
              label={
                <Typography
                  component="p"
                  variant="p"
                  style={{
                    fontFamily: "Poppins",
                    // color: "#00638F",
                    fontSize: 16,
                  }}
                >
                  Formation à l'utilisation (50,00€)
                </Typography>
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          ></div>
        </div>
      </Grid>
    </Grid>
  );
}
