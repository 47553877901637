import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FinalForm from "../Utils/FinalForm";
import InputText from "../Inputs/InputText";
import RequestAPI from "../Utils/API";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
import { Link, Redirect } from "react-router-dom";
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/welcome.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "50%",
    borderRadius: 25,
    backgroundColor: "#00638F",
  },
  resetPassword: {
    textDecoration: "underline",
    cursor: "pointer",
    marginTop: 10,
  },
  resetFlex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  field: {
    borderRadius: 50,
    backgroundColor: "#000",
  },
  submitReset: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: 25,
    backgroundColor: "#00638F",
  },
}));

export default function SignInSide({ firebase }) {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [emailReset, setEmailReset] = useState("");
  const onSubmit = (values) => {
    if (!values.email) {
      enqueueSnackbar("Merci de renseigner un email !", {
        variant: "error",
      });
    }
    if (values.password < 3) {
      enqueueSnackbar("Merci de renseigner un mot de passe !", {
        variant: "error",
      });
    }
    firebase
      .doSignInWithEmailAndPassword(values.email, values.password)
      .then(async (success) => {
        console.log(await success.user.getIdToken());
        setCookie("userToken", success.user.uid, {
          path: "/",
          maxAge: 86400,
        });
        setCookie("email", values.email, {
          path: "/",
          maxAge: 86400,
        });
      })
      .catch((error) => {
        enqueueSnackbar("Le mot de passe ou l'adresse mail est invalide", {
          variant: "error",
        });
      });
  };

  const handlePasswordReset = () => {
    if (!emailReset) {
      enqueueSnackbar("Merci de renseigner un email !", {
        variant: "error",
      });
      return;
    }

    firebase.auth
      .sendPasswordResetEmail(emailReset)
      .then(() => {
        enqueueSnackbar("Email envoyé !", {
          variant: "success",
        });
      })
      .catch((error) => {
        if (error.code === "auth/invalid-email") {
          enqueueSnackbar("Merci de renseigner un email valide !", {
            variant: "error",
          });
        } else if (error.code === "auth/user-not-found") {
          enqueueSnackbar("Cette email n'est pas ratachée à un compte !", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Une erreur s'est produite, merci de réessayer !", {
            variant: "error",
          });
        }
      });
  };
  return (
    <Grid container component="main" className={classes.root}>
      {cookies.userToken && <Redirect to="/" />}
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h5"
            style={{
              fontFamily: "MostraThree",
              marginTop: 50,
              fontSize: 60,
              color: "#00638F",
            }}
          >
            Maint & Sea
          </Typography>
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              marginTop: 50,
              color: "#00638F",
            }}
          >
            Connexion
          </Typography>
          <FinalForm onSubmit={onSubmit} style={classes.form}>
            <InputText name="email" label="Adresse mail" required></InputText>
            <InputText
              name="password"
              label="Mot de passe"
              type="password"
              required
            ></InputText>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Se connecter
              </Button>
            </div>
          </FinalForm>
          <Link to="/register">
            {" "}
            <Typography variant="body2" color="textSecondary" align="center">
              Pas encore inscrit ?
            </Typography>
          </Link>

          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            className={classes.resetPassword}
            onClick={() => setOpen(true)}
          >
            Mot de passe oublié ?
          </Typography>

          <Grid container>
            <Grid item xs></Grid>
            <Grid item></Grid>
          </Grid>
          <Box mt={5}>
            <Typography variant="body2" color="textSecondary" align="center">
              {"Copyright © "}
              Maint & Sea
              {new Date().getFullYear()}
              {"."}
            </Typography>
          </Box>
        </div>
      </Grid>
      <Dialog open={open} fullWidth onClose={() => setOpen(false)}>
        <DialogTitle>Mot de passe oublié ?</DialogTitle>
        <DialogContent>
          <Container className={classes.resetFlex}>
            <Typography variant="body1" align="center">
              Entrez votre adresse mail pour recevoir un mail de
              réinitialisation de mot de passe
            </Typography>
            <TextField
              name="emailReset"
              label="Adresse mail"
              onChange={(e) => setEmailReset(e.target.value)}
              value={emailReset}
              required
              type="email"
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.submitReset}
              onClick={handlePasswordReset}
            >
              Réinitialiser mon mot de passe
            </Button>
          </Container>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
