import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputAdornment,
  makeStyles,
  TablePagination,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Visibility, Edit } from "@material-ui/icons";
import MaintenanceItem from "./MaintenanceItem";
import { Link, useHistory } from "react-router-dom";
import FinalForm from "../../Utils/FinalForm";
import InputText from "../../Inputs/InputText";

const MaintenanceTableau = ({ firebase, shipId }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = useState("");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [confirmEvent, setConfirmEvent] = useState(false);
  const [deleteEvent, setDeleteEvent] = useState(false);
  const [pastLoaded, setPastLoaded] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [openReport, setOpenReport] = useState(false);

  useEffect(() => {
    if (!pastLoaded && search) {
      setLoading(true);
      firebase.db
        .collection("Ships")
        .doc(shipId)
        .collection("event_maintenance")

        .orderBy("date", "asc")
        .get()
        .then((snapshot) => {
          let tmp = [];
          snapshot.forEach((doc) => {
            tmp.push({ ...doc.data(), id: doc.id });
          });
          setEvents(tmp);
          setLoading(false);
        });
      setPastLoaded(true);
    }
  }, [search]);

  const history = useHistory();
  useEffect(() => {
    setConfirmEvent(false);
    setDeleteEvent(false);
  }, [selectedEvent]);

  const fetchEvents = () => {
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("event_maintenance")
      .where("date", ">=", moment().subtract(1, "week").format("YYYY/MM/DD"))
      .orderBy("date", "asc")
      .get()
      .then((snapshot) => {
        let tmp = [];

        if (!pastLoaded) {
          firebase.db
            .collection("Ships")
            .doc(shipId)
            .collection("event_maintenance")
            .where(
              "date",
              "<",
              moment().subtract(1, "week").format("YYYY/MM/DD")
            )
            .where("validated", "==", false)
            .orderBy("date", "asc")
            .get()
            .then((result) => {
              result.forEach((doc) => {
                tmp.push({ ...doc.data(), id: doc.id });
              });
              snapshot.forEach((doc) => {
                tmp.push({ ...doc.data(), id: doc.id });
              });
              setEvents(tmp);
              setLoading(false);
            });
        } else {
          snapshot.forEach((doc) => {
            tmp.push({ ...doc.data(), id: doc.id });
          });
          setEvents(tmp);
          setLoading(false);
        }
      });
  };
  const handleSubmit = async (values) => {
    firebase.db
      .collection("rapports")
      .add({
        createdAt: new Date(),
        start: values.startDate,
        end: values.endDate,
        ship: firebase.db.collection("ships").doc(shipId),
        status: "pending",
      })
      .then(() => {
        enqueueSnackbar(
          "Votre demande de rapport a bien été enregistrée, nos équipes vous contacteront rapidement",
          {
            variant: "success",
          }
        );
        setOpenReport(false);
      })
      .catch((err) => {
        enqueueSnackbar("Une erreur est survenue", { variant: "error" });
      });
  };
  const handleDelete = () => {
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("event_maintenance")
      .doc(selectedEvent.id)
      .delete()
      .then(() => {
        enqueueSnackbar("Évènement supprimé !", { variant: "success" });
        fetchEvents();
        setSelectedEvent(null);
      })
      .catch(() => {
        enqueueSnackbar("Erreur lors de la suppression", { variant: "error" });
      });
  };

  const handleConfirm = async () => {
    let unit;
    if (selectedEvent.recurrence_unit === "Mois") {
      unit = "months";
    } else if (selectedEvent.recurrence_unit === "Semaines") {
      unit = "weeks";
    } else if (selectedEvent.recurrence_unit === "Année") {
      unit = "years";
    } else if (selectedEvent.recurrence_unit === "Jours") {
      unit = "days";
    } else {
      unit = false;
    }

    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("event_maintenance")
      .doc(selectedEvent.id)
      .update({
        validated: true,
      })

      .then(() => {
        enqueueSnackbar("Évènement validé !", { variant: "success" });
        fetchEvents();
        setSelectedEvent(null);
        if (unit && selectedEvent.recurrence) {
          firebase.db
            .collection("Ships")
            .doc(shipId)
            .collection("event_maintenance")
            .add({
              ...selectedEvent,
              validated: false,
              date: moment(selectedEvent.date)
                .add(unit, selectedEvent.recurrence)
                .format("YYYY/MM/DD"),
            })
            .then(() => fetchEvents());
        }
      })
      .catch(() => {
        enqueueSnackbar("Erreur lors de la validation", { variant: "error" });
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [events, setEvents] = useState([]);
  useEffect(() => {
    fetchEvents();
  }, []);

  const withFilters = (item) => {
    if (search == "") {
      return true;
    }
    if (item.type?.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    if (item.title?.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    if (item.descriptionDetail?.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    if (item.recurrence?.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    if (item.recurrence_unit?.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    if (
      moment(item.date)
        .format("DD/MM/YYYY")
        .toLowerCase()
        .includes(search.toLowerCase())
    ) {
      return true;
    }
    if (item.descriptions) {
      const res = item.descriptions.map((e) => {
        if (e.category?.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
        if (e.label?.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
        if (e.value?.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
        return false;
      });
      return res.some((el) => el);
    }

    return false;
  };

  return (
    <Container>
      <div className={classes.SearchAndCardWrapper}>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              marginTop: -25,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                minWidth: 120,
              }}
            >
              <div
                style={{
                  width: 16,
                  height: 16,
                  borderRadius: 8,
                  margin: 10,
                  backgroundColor: "#C0C0C0",
                }}
              ></div>
              <Typography style={{ marginBottom: 0 }}>: Terminé</Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                minWidth: 150,
              }}
            >
              <div
                style={{
                  width: 16,
                  height: 16,
                  borderRadius: 8,
                  margin: 10,
                  backgroundColor: "#8CE15B",
                }}
              ></div>
              <Typography style={{ marginBottom: 0 }}>
                : Dans plus d'un mois
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                minWidth: 150,
              }}
            >
              <div
                style={{
                  width: 16,
                  height: 16,
                  borderRadius: 8,
                  margin: 10,
                  backgroundColor: "#FF8E2C",
                }}
              ></div>
              <Typography style={{ marginBottom: 0 }}>
                : Dans moins d'un mois
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                minWidth: 150,
              }}
            >
              <div
                style={{
                  width: 16,
                  height: 16,
                  borderRadius: 8,
                  margin: 10,
                  backgroundColor: "#FE4834",
                }}
              ></div>
              <Typography style={{ marginBottom: 0 }}>
                : J-1 ou date dépassée
              </Typography>
            </div>
          </div>
          <TextField
            className={classes.searchbar}
            variant="outlined"
            placeholder="Recherchez tous vos événéments antérieurs"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    style={{ width: 25, height: 25 }}
                    src={require("../../Icons/search.png")}
                  />
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <Link
          to={"/" + shipId + "/addMaintenance"}
          style={{ textDecoration: "none" }}
          className={classes.card}
        >
          <svg
            style={{ fontSize: 45 }}
            class="MuiSvgIcon-root jss174"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              fill="#329646"
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
            ></path>
          </svg>
        </Link>
      </div>
      <Button
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={() => setOpenReport(true)}
      >
        Demande de rapport
      </Button>
      <TableContainer>
        {loading && <CircularProgress color="primary" />}
        <Table aria-label="simple table" className={classes.head}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.col}>DATE</TableCell>
              <TableCell className={classes.col}>STATUT</TableCell>
              <TableCell className={classes.col}>POSTE</TableCell>
              <TableCell className={classes.col}>ACTION</TableCell>
              <TableCell className={classes.col}>DÉTAILS</TableCell>
              <TableCell className={classes.col}>COMMENTAIRE</TableCell>
              <TableCell className={classes.col}>VOIR PLUS</TableCell>
              <TableCell className={classes.col}>MODIFIER</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events
              .filter((item) => withFilters(item))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id} className={classes.row}>
                  <TableCell>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      {moment(row.date).format("DD/MM/YYYY")}
                      <Status item={row} />
                    </div>
                  </TableCell>
                  <TableCell>
                    {row.validated ? "Terminé" : "En cours"}
                  </TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>
                    {row.descriptions.map((e) => e.value).join(", ")}
                  </TableCell>
                  <TableCell>{row.descriptionDetail}</TableCell>
                  <TableCell className={classes.col}>
                    <Visibility
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedEvent(row);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Link
                      to={"/" + shipId + "/maintenance/" + row.id}
                      style={{ textDecoration: "none", color: "inherit" }}
                      className={classes.card}
                    >
                      <Edit style={{ cursor: "pointer" }} />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={events.filter((item) => withFilters(item)).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Lignes par page"
      />
      {!!selectedEvent && (
        <Dialog
          open={selectedEvent}
          onClose={() => setSelectedEvent(null)}
          fullWidth
        >
          <DialogTitle>
            <h2
              style={{ fontWeight: "bold", color: "#00638F", marginBottom: 0 }}
            >
              Évènement{" "}
              {selectedEvent.validated ? "validé" : "en attente de validation"}
            </h2>
          </DialogTitle>
          <MaintenanceItem firebase={firebase} item={selectedEvent} />

          <DialogActions>
            <Button onClick={() => setSelectedEvent(null)}>Fermer</Button>
            <Button onClick={() => setDeleteEvent(true)}>Supprimer</Button>
            {!selectedEvent.validated && (
              <>
                <Button onClick={() => setConfirmEvent(true)}>
                  Valider l'évènement
                </Button>
              </>
            )}
          </DialogActions>
          <Dialog
            open={confirmEvent}
            onClose={() => setConfirmEvent(false)}
            fullWidth
          >
            <DialogTitle>Attention</DialogTitle>
            <DialogContent>
              <Typography>
                Voulez vous vraiment valider cet évènement ? Il ne sera plus
                possible de le modifier ni de le supprimer
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmEvent(false)}>Annuler</Button>

              <Button onClick={handleConfirm}>Valider l'évènement</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={deleteEvent}
            onClose={() => setDeleteEvent(false)}
            fullWidth
          >
            <DialogTitle>Attention</DialogTitle>
            <DialogContent>
              <Typography>
                Voulez vous vraiment supprimer cet évènement ? Cette action est
                irréversible
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteEvent(false)}>Annuler</Button>

              <Button onClick={handleDelete}>Supprimer l'évènement</Button>
            </DialogActions>
          </Dialog>
        </Dialog>
      )}
      <Dialog open={openReport} onClose={() => setOpenReport(false)} fullWidth>
        <DialogTitle>
          <h2
            style={{
              fontWeight: "bold",
              color: "#00638F",
              marginBottom: 0,
            }}
          >
            Rapport pro format
          </h2>
        </DialogTitle>
        <DialogContent>
          <p>
            Synthèse de tous vos évènements réglementaires, machines et
            hydrocarbures sur une période choisie.
          </p>
          <p>Sur quelle période souhaitez vous éditer un rapport ?</p>
          <FinalForm
            onSubmit={handleSubmit}
            initialValues={{
              startDate: moment().format("YYYY-MM-DD"),
              endDate: moment().format("YYYY-MM-DD"),
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <p style={{ margin: 10 }}>du</p>
              <InputText name="startDate" type="date" required />
              <p style={{ margin: 10 }}>au</p>
              <InputText name="endDate" type="date" required />
            </div>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
            >
              {"Demander un rapport"}
            </Button>
          </FinalForm>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenReport(false)}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
const Status = ({ item }) => {
  const [color, setColor] = useState("transparent");

  useEffect(() => {
    const diff = moment(item.date).diff(moment(), "day", true);
    if (item.validated) {
      setColor("#C0C0C0");
    } else if (diff >= 30) {
      setColor("#8CE15B");
    } else if (diff >= 1) {
      setColor("#FF8E2C");
    } else {
      setColor("#FE4834");
    }
  }, [item]);

  return (
    <div
      style={{
        width: 16,
        height: 16,
        borderRadius: 8,
        marginLeft: 10,
        backgroundColor: color,
      }}
    ></div>
  );
};

const useStyles = makeStyles((theme) => ({
  col: {
    color: "#00638F",
    borderBottomWidth: 0,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#FFF",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#DCEFF6",
    },
  },
  searchbar: {
    display: "flex",
    borderRadius: 50,
    width: "100%",
    margin: "10px auto",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "no-wrap",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    padding: "0 15px",
    textAlign: "center",
  },
  SearchAndCardWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export default MaintenanceTableau;
