import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router";

const Redirect = ({ firebase }) => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const history = useHistory();
  useEffect(() => {
    (async () => {
      const shipsRef = firebase.db.collection("Ships");

      const query = await shipsRef
        .where("armateur", "==", cookies.userToken)
        .orderBy("createdAt", "desc")
        .get();
      if (query.empty) {
        history.replace("/dashboard");
      } else {
        history.replace(`/ship/${query.docs[0].id}/calendrier`);
      }
    })();
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />{" "}
    </div>
  );
};

export default Redirect;
