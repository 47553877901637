import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import MaintenanceItem from "./MaintenanceItem";
import CahierItem from "./CahierItem";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
const localizer = momentLocalizer(moment); // or globalizeLocalizer

function Event({ event }) {
  const [color, setColor] = useState("");

  useEffect(() => {
    const diff = moment(event.date).diff(moment(), "day", true);
    if (event.validated) {
      setColor("#C0C0C0");
    } else if (diff >= 30) {
      setColor("#8CE15B");
    } else if (diff >= 1) {
      setColor("#FF8E2C");
    } else {
      setColor("#FE4834");
    }
  }, [event]);

  return (
    <span
      style={{
        backgroundColor: color,
        padding: 5,
        borderRadius: 5,
        display: "block",
      }}
    >
      <strong
        style={{
          display: "block",
          width: "100%",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {event.title || event.type}
      </strong>
    </span>
  );
}

const MyCalendar = ({ firebase, shipId }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [cahiers, setCahiers] = useState([]);
  const [maintenances, setMaintenances] = useState([]);
  const [mode, setMode] = useState("event_maintenance");
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [confirmEvent, setConfirmEvent] = useState(false);
  const [deleteEvent, setDeleteEvent] = useState(false);
  const [maintenanceLoading, setMaintenanceLoading] = useState(true);
  const [cahierLoading, setCahierLoading] = useState(true);

  const history = useHistory();

  const handleSlot = (slot) => {
    if (slot.action == "doubleClick") {
      const params = new URLSearchParams({
        date: moment(slot.start).format("YYYY-MM-DD"),
      });

      history.push(
        `/${shipId}/${
          mode === "event_maintenance" ? "addMaintenance" : "addCahier"
        }?${params.toString()}`
      );
    }
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setConfirmEvent(false);
    setDeleteEvent(false);
  }, [selectedEvent]);

  const fetchEvents = () => {
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("event_maintenance")
      .get()
      .then((snapshot) => {
        let tmp = [];
        snapshot.forEach((doc) => {
          tmp.push({ ...doc.data(), id: doc.id });
        });
        setMaintenances(tmp);
        setMaintenanceLoading(false);
      });
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("event_cahier")
      .get()
      .then((snapshot) => {
        let tmp = [];

        snapshot.forEach((doc) => {
          tmp.push({ ...doc.data(), id: doc.id });
        });
        setCahiers(tmp);
        setCahierLoading(false);
      });
  };

  const handleDelete = () => {
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection(mode)
      .doc(selectedEvent.id)
      .delete()
      .then(() => {
        enqueueSnackbar("Évènement supprimé !", { variant: "success" });
        fetchEvents();
        setSelectedEvent(null);
      })
      .catch(() => {
        enqueueSnackbar("Erreur lors de la suppression", { variant: "error" });
      });
  };
  const handleConfirm = async () => {
    let unit;
    if (selectedEvent.recurrence_unit === "Mois") {
      unit = "months";
    } else if (selectedEvent.recurrence_unit === "Semaines") {
      unit = "weeks";
    } else if (selectedEvent.recurrence_unit === "Année") {
      unit = "years";
    } else if (selectedEvent.recurrence_unit === "Jours") {
      unit = "days";
    } else {
      unit = false;
    }
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection(mode)
      .doc(selectedEvent.id)
      .update({
        validated: true,
      })
      .then(() => {
        enqueueSnackbar("Évènement validé !", { variant: "success" });
        fetchEvents();
        setSelectedEvent((prev) => ({ ...prev, validated: true }));
        if (unit && selectedEvent.recurrence) {
          firebase.db
            .collection("Ships")
            .doc(shipId)
            .collection(mode)
            .add({
              ...selectedEvent,
              validated: false,
              date: moment(selectedEvent.date)
                .add(unit, selectedEvent.recurrence)
                .format("YYYY/MM/DD"),
            });
        }
      })
      .catch(() => {
        enqueueSnackbar("Erreur lors de la validation", { variant: "error" });
      });
  };
  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    if (mode === "event_maintenance") {
      setEvents((prev) => maintenances);
    } else {
      console.log("changed");
      setEvents((prev) => cahiers);
    }
  }, [maintenances, cahiers, mode]);

  return (
    <Container>
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}
      >
        <ButtonGroup>
          <Button
            onClick={() => {
              setMode("event_maintenance");
            }}
            color={mode === "event_maintenance" ? "primary" : "default"}
            variant="contained"
          >
            Maintenance réglementaire{" "}
            {maintenanceLoading && (
              <CircularProgress
                color={mode === "event_maintenance" ? "default" : "primary"}
              />
            )}
          </Button>
          <Button
            onClick={() => {
              setMode("event_cahier");
            }}
            color={mode === "event_cahier" ? "primary" : "default"}
            variant="contained"
          >
            Cahier Machine{" "}
            {cahierLoading && (
              <CircularProgress
                color={mode === "event_maintenance" ? "default" : "primary"}
              />
            )}
          </Button>
        </ButtonGroup>
      </div>
      <Calendar
        onSelectSlot={handleSlot}
        localizer={localizer}
        events={events}
        startAccessor="date"
        endAccessor="date"
        views={["month"]}
        style={{ height: 650 }}
        popup={true}
        components={{
          event: Event,
        }}
        selectable={true}
        onSelectEvent={(event) => setSelectedEvent(event)}
        showAllEvents
        messages={{
          today: "Aujourd'hui",
          next: "Suivant",
          previous: "Précédent",
        }}
      />
      <Typography style={{ textAlign: "center" }}>
        Vous pouvez double cliquer sur une date pour y créer un évènement
      </Typography>
      {!!selectedEvent && (
        <Dialog
          open={selectedEvent}
          onClose={() => setSelectedEvent(null)}
          fullWidth
        >
          <DialogTitle>
            <h2
              style={{ fontWeight: "bold", color: "#00638F", marginBottom: 0 }}
            >
              Évènement{" "}
              {selectedEvent.validated ? "validé" : "en attente de validation"}
            </h2>
          </DialogTitle>

          {mode === "event_maintenance" ? (
            <MaintenanceItem firebase={firebase} item={selectedEvent} />
          ) : (
            <CahierItem firebase={firebase} item={selectedEvent} />
          )}
          <DialogActions>
            <Button onClick={() => setSelectedEvent(null)}>Fermer</Button>

            {!selectedEvent.validated && (
              <>
                <Button onClick={() => setConfirmEvent(true)}>
                  Valider l'évènement
                </Button>
              </>
            )}
            <Button onClick={() => setDeleteEvent(true)}>Supprimer</Button>

            <Button
              onClick={() =>
                history.push(
                  mode === "event_maintenance"
                    ? "/" + shipId + "/maintenance/" + selectedEvent.id
                    : "/" + shipId + `/${selectedEvent.id}` + "/editCahier"
                )
              }
            >
              Modifier
            </Button>
          </DialogActions>
          <Dialog
            open={confirmEvent}
            onClose={() => setConfirmEvent(false)}
            fullWidth
          >
            <DialogTitle>Attention</DialogTitle>
            <DialogContent>
              <Typography>
                Voulez vous vraiment valider cet évènement ? Il ne sera plus
                possible de le modifier ni de le supprimer
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmEvent(false)}>Annuler</Button>

              <Button onClick={handleConfirm}>Valider l'évènement</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={deleteEvent}
            onClose={() => setDeleteEvent(false)}
            fullWidth
          >
            <DialogTitle>Attention</DialogTitle>
            <DialogContent>
              <Typography>
                Voulez vous vraiment supprimer cet évènement ? Cette action est
                irréversible
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteEvent(false)}>Annuler</Button>

              <Button onClick={handleDelete}>Supprimer l'évènement</Button>
            </DialogActions>
          </Dialog>
        </Dialog>
      )}
    </Container>
  );
};

export default MyCalendar;
