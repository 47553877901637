import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FinalForm from "../Utils/FinalForm";
import InputText from "../Inputs/InputText";
import RequestAPI from "../Utils/API";
import { useCookies } from "react-cookie";
import Compressor from "compressorjs";
import { useSnackbar } from "notistack";
import { Redirect, useHistory, Link } from "react-router-dom";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import InputSelect from "../Inputs/InputSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/welcome.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "50%",
    borderRadius: 25,
    backgroundColor: "#00638F",
  },
  inputSemi: {
    width: "48%",
  },
  inputsLine1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  grid: {
    width: "100%",
  },
}));

export default function AddPartenaire({ firebase }) {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const partRef = firebase.db.collection("Partenariats");
  const [coques, setCoques] = useState([]);
  const [photo, setPhoto] = useState("");
  const [activities, setActivities] = useState([]);

  //   useEffect(() => {
  //     firebase.db
  //       .collection("ActivityTypes")
  //       .get()
  //       .then((snapshot) => {
  //         let tmp = [];

  //         snapshot.forEach((item) => {
  //           tmp.push({
  //             label: item.data().value,
  //             id: item.data().value,
  //           });
  //         });
  //         console.log("activities", tmp);

  //         setActivities(tmp);
  //       });
  //     firebase.db
  //       .collection("CoqueTypes")
  //       .get()
  //       .then((snapshot) => {
  //         let tmp = [];

  //         snapshot.forEach((item) => {
  //           tmp.push({
  //             label: item.data().value,
  //             id: item.data().value,
  //           });
  //         });
  //         console.log("coques", tmp);
  //         setCoques(tmp);
  //       });
  //   }, []);

  const handleFile = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    compressFile(file, 0.8);
  };

  const compressFile = (file, quality) => {
    if (quality < 0) {
      enqueueSnackbar("Fichier trop volumineux !", { variant: "error" });
      return;
    }
    const compressor = new Compressor(file, {
      quality: quality,
      convertSize: 1000000,
      maxHeight: 1000,
      maxWidth: 1000,
      success(result) {
        const reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onloadend = function () {
          var base64data = reader.result;
          if (base64data.length * (3 / 4) < 1000000) {
            setPhoto(base64data);
          } else {
            compressFile(result, compressor.options.quality - 0.1);
          }
        };
      },
      error() {
        enqueueSnackbar("Échec de l'envoi du fichier  !", { variant: "error" });
      },
    });
  };

  const handleSubmit = async (values) => {
    partRef
      .add({
        createdAt: new Date(),
        image: photo,
        title: values?.title,
        description: values?.description,
        link: values?.lien,
        view: 0,
      })
      .then((val) => {
        enqueueSnackbar("Partenaire ajouté !", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Erreur lors de l'ajout du navire", {
          variant: "error",
        });
      });
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid className={classes.grid}>
        <Typography
          component="h1"
          variant="h1"
          style={{
            fontFamily: "MostraThree",
            fontSize: 50,
            color: "#00638F",
            textDecoration: "none",
            margin: "50px 0",
            marginLeft: "15%",
          }}
        >
          MAINT & SEA
        </Typography>
        <div
          style={{
            padding: "5%",
          }}
        >
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              color: "#00638F",
              fontSize: 40,
              textAlign: "center",
            }}
          >
            Ajouter un partenaire
          </Typography>
          <FinalForm onSubmit={handleSubmit} style={classes.form}>
            <InputText name="title" label="Titre" required></InputText>

            <div className={classes.inputDesc}>
              <InputText
                name="description"
                label="Description"
                multiline
              ></InputText>
            </div>
            <div className={classes.inputDesc}>
              <InputText name="lien" label="Lien"></InputText>
            </div>

            {!!photo && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
                className={classes.btnWrapper}
              >
                <img
                  src={photo}
                  style={{
                    maxWidth: "80%",
                    maxHeight: "80%",
                    objectFit: "contain",
                    marginTop: 10,
                  }}
                />
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              className={classes.btnWrapper}
            >
              <Button
                variant="contained"
                component="label"
                color="primary"
                className={classes.submit}
                onChange={handleFile}
              >
                {photo ? "Modifier la" : "Ajouter une"} photo
                <input type="file" hidden accept="image/*" />
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Créer
              </Button>
            </div>
          </FinalForm>
        </div>
      </Grid>
    </Grid>
  );
}
