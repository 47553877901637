import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FinalForm from "../Utils/FinalForm";
import InputText from "../Inputs/InputText";
import RequestAPI from "../Utils/API";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
import { Redirect } from "react-router-dom";
import { Field } from "react-final-form";
import { Checkbox, FormControlLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/welcome.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "50%",
    borderRadius: 25,
    backgroundColor: "#00638F",
  },
}));

export default function Register({ firebase }) {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [itsSecondPage, setItsSecondPage] = useState(false);

  const onSubmit = (values) => {
    if (!values.email) {
      enqueueSnackbar("Merci de renseigner un email !", {
        variant: "error",
      });
      return;
    }
    if (values.password.length < 6) {
      enqueueSnackbar(
        "Merci de renseigner un mot de passe d'au moins 6 caractères !",
        {
          variant: "error",
        }
      );
      return;
    }
    if (values.password1 != values.password) {
      enqueueSnackbar("Les mots de passe ne correspondent pas", {
        variant: "error",
      });
      return;
    }
    firebase
      .doCreateUserWithEmailAndPassword(values.email, values.password)
      .then(async (success) => {
        delete values.password;
        delete values.password1;
        await firebase.db
          .collection("Armateurs")
          .doc(values.email)
          .set({ ...values, uid: success.user.uid, createdAt: new Date() });
        setCookie("userToken", success.user.uid, {
          path: "/",
          maxAge: 86400,
        });
        setCookie("email", values.email, {
          path: "/",
          maxAge: 86400,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.code === "auth/invalid-email") {
          enqueueSnackbar("Merci de renseigner un email valide !", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Cette adresse email est déjà prise !", {
            variant: "error",
          });
        }
      });
  };

  return (
    <Grid container component="main" className={classes.root}>
      {cookies.userToken && <Redirect to="/dashboard" />}
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h5"
            style={{
              fontFamily: "MostraThree",
              marginTop: 50,
              fontSize: 60,
              color: "#00638F",
            }}
          >
            Maint & Sea
          </Typography>
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              marginTop: 50,
              color: "#00638F",
            }}
          >
            Inscription
          </Typography>
          <FinalForm onSubmit={onSubmit} style={classes.form}>
            {!itsSecondPage && (
              <div>
                <InputText name="lastName" label="Nom" required></InputText>
                <InputText name="firstName" label="Prénom" required></InputText>
                <InputText
                  name="email"
                  label="Adresse mail"
                  required
                ></InputText>
                <InputText
                  name="password"
                  label="Mot de passe"
                  type="password"
                  required
                ></InputText>
                <InputText
                  name="password1"
                  label="Confirmer le mot de passe"
                  type="password"
                  required
                ></InputText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={() => {
                      if (
                        !document.querySelector("[name=lastName]").value ||
                        !document.querySelector("[name=firstName]").value ||
                        !document.querySelector("[name=email]").value ||
                        !document.querySelector("[name=password]").value
                      ) {
                        enqueueSnackbar("Veuillez remplir tous les champs.", {
                          variant: "error",
                        });
                        return;
                      }
                      if (
                        document.querySelector("[name=password]").value !==
                        document.querySelector("[name=password1]").value
                      ) {
                        enqueueSnackbar(
                          "Les mots de passe ne sont pas identiques.",
                          {
                            variant: "error",
                          }
                        );
                        return;
                      }
                      setItsSecondPage(true);
                    }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Suivant
                  </Button>
                </div>
              </div>
            )}
            {itsSecondPage && (
              <div>
                <InputText
                  name="raisonSociale"
                  label="Raison sociale"
                  required
                ></InputText>
                <InputText
                  name="SIRET"
                  label="Numéro SIRET"
                  required
                ></InputText>
                <InputText name="phone" label="Téléphone" required></InputText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <InputText
                    name="streetNumber"
                    label="Numéro"
                    required
                    customStyle={{ flex: 1 }}
                  ></InputText>
                  <InputText
                    name="streetName"
                    label="Nom de voie"
                    required
                    customStyle={{ flex: 3 }}
                  ></InputText>
                </div>
                <InputText name="addressLine2" label="Ligne 2"></InputText>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <InputText
                    name="CP"
                    label="Code postal"
                    required
                    customStyle={{ flex: 1 }}
                  ></InputText>
                  <InputText
                    name="city"
                    label="Ville"
                    required
                    customStyle={{ flex: 2 }}
                  ></InputText>
                </div>
                <InputText name="country" label="Pays" required></InputText>
                <Field name="cgv">
                  {(props) => (
                    <FormControlLabel
                      control={
                        <Checkbox name="checkedB" color="primary" required />
                      }
                      label={
                        <p>
                          J'ai lu et j'accepte les{" "}
                          <a
                            target="_blank"
                            href="/Conditions Générales de Vente.pdf"
                          >
                            conditions générales de vente
                          </a>
                        </p>
                      }
                    />
                  )}
                </Field>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <Button
                    onClick={() => setItsSecondPage(false)}
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    style={{ width: "25%" }}
                  >
                    Précédent
                  </Button>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    style={{ width: "70%" }}
                  >
                    S'inscrire
                  </Button>
                </div>
              </div>
            )}
          </FinalForm>
          <a href="/login">
            {" "}
            <Typography variant="body2" color="textSecondary" align="center">
              Retour à la connexion
            </Typography>
          </a>

          <Grid container>
            <Grid item xs></Grid>
            <Grid item></Grid>
          </Grid>
          <Box mt={5}>
            <Typography variant="body2" color="textSecondary" align="center">
              {"Copyright © "}
              Maint & Sea
              {new Date().getFullYear()}
              {"."}
            </Typography>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
