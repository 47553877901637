import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import pink from "@material-ui/core/colors/pink";
import grey from "@material-ui/core/colors/grey";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00638F",
      light: "#0088c2",
      dark: "#00405c",
    },
    secondary: {
      main: pink["A400"],
      light: "#ffffff",
    },
    default: {
      main: "#ffffff",
    },
  },
  status: {
    danger: "orange",
  },
});
