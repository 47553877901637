import { makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";

const ShipListItem = ({ ship, firebase }) => {
  const classes = useStyles();
  const location = useLocation();
  const [borderColor, setBorderColor] = useState("#B5B5B5");
  const [backgroundColor, setBackgroundColor] = useState("#FFF");
  const [color, setColor] = useState("#B5B5B5");

  useEffect(() => {
    if (location.pathname.includes(ship.id)) {
      setBackgroundColor("#00638F");
      setColor("#fff");
    } else {
      setBackgroundColor("#FFF");
      setColor("#B5B5B5");
    }
    firebase.db
      .collection("Ships")
      .doc(ship.id)
      .collection("event_maintenance")
      .where("validated", "==", false)
      .orderBy("date")
      .limit(1)
      .onSnapshot((docs) => {
        //console.log(docs.empty);
        if (docs.empty) {
          setBorderColor("#8CE15B");
        }
        docs.forEach((doc) => {
          const diff = moment(doc.data().date).diff(moment(), "day", true);

          if (diff >= 30) {
            setBorderColor("#8CE15B");
          } else if (diff >= 7) {
            setBorderColor("#FF8E2C");
          } else {
            setBorderColor("#FE4834");
          }
        });
      });
  }, [location]);
  return (
    <Link
      to={"/ship/" + ship.id + "/calendrier"}
      style={{ textDecoration: "none" }}
    >
      <div
        style={{
          border: "2px solid",
          borderColor: borderColor,
          backgroundColor: backgroundColor,
          marginTop: 50,
          padding: 50,
          paddingBottom: 10,
          paddingTop: 10,
          borderRadius: 50,
          cursor: "pointer",
        }}
      >
        <Typography
          component="h3"
          variant="h5"
          style={{
            fontFamily: "Poppins",
            fontSize: 25,
            color: color,
            textDecoration: "none",
          }}
        >
          {ship.name}
        </Typography>
      </div>
    </Link>
  );
};

const useStyles = makeStyles((theme) => ({}));

export default ShipListItem;
