import React, { useEffect, useState } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import LeftMenu from "./LeftMenu";
import { useCookies } from "react-cookie";

const useStyles = makeStyles((theme) => ({
  grow: {
    display: "flex",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  appbar: {
    backgroundColor: "#00638F",
  },
}));

export default function Header({ firebase, children }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [isLeftMenuOpen, setIsLeftMenuOpen] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [size, setSize] = useState(0);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const logout = () => {
    removeCookie(["authToken"]);
  };

  const displayWindowSize = () => {
    if (window.innerWidth > 700) {
      setSize(true);
    } else {
      setSize(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth > 700) {
      setSize(true);
    } else {
      setSize(false);
    }
  }, [])

  window.addEventListener("resize", displayWindowSize);

  
  return (
    <div className={classes.grow}>
      {size && (
        <div
          style={{
            position: "fixed",
          }}
        >
          <LeftMenu
          firebase={firebase}
            open={isLeftMenuOpen}
            switchMenu={setIsLeftMenuOpen}
          ></LeftMenu>
        </div>
      )}
      {!size && (
        <div
          style={{
            position: "fixed",
            zIndex: 999,
            transition: "opacity 300ms, transform 300ms",
            transform: isLeftMenuOpen ? "translate(0px)" : "translate(-350px)",
          }}
        >
          <LeftMenu
           firebase={firebase}
            open={isLeftMenuOpen}
            switchMenu={setIsLeftMenuOpen}
          ></LeftMenu>
        </div>
      )}
      {!size && (
        <AppBar
          position="absolute"
          classes={{
            root: classes.appbar,
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon
                onClick={() => {
                  setIsLeftMenuOpen(!isLeftMenuOpen);
                }}
              />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}

      <div
        style={{
          paddingLeft: size ? 350 : 0,
          width: "100%",
          paddingTop: size ? 0 : 30,
        }}
      >
        {children}
      </div>
    </div>
  );
}
