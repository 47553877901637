import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
// import TextField from "@material-ui/core/TextField";
import { LinearProgress, TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import moment from "moment";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/welcome.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "50%",
    borderRadius: 25,
    backgroundColor: "#00638F",
  },
  label: {
    marginTop: 20,
    marginLeft: 20,
    fontSize: 22,
    color: "rgb(134 134 134)",
    marginBottom: "-12px",
  },
  fileInput: {
    borderRadius: 25,
    backgroundColor: "#00638F",
    width: "50%",
    color: "#ffffff",
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column !important",
    flexWrap: "wrap",
    color: "#ffffff",
  },
  backButton: {
    marginLeft: 20,
    marginTop: 20,
  },
  heading: {
    fontFamily: "Poppins",
    color: "#00638F",
    fontSize: 25,
  },
  subtitle: {
    fontFamily: "Poppins",
    color: "#00638F",
  },
}));

export default function Releve({ firebase }) {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [releve, setReleve] = useState(null);
  const [finishLoad, setFinishLoad] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const options = ["Jours", "Mois", "Semaines", "Année", "Heures compteur"];
  const { shipId, id } = useParams();
  useEffect(() => {
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .collection("releves")
      .doc(id)
      .get()
      .then((res) => {
        console.log(res.data().details);
        setReleve(res.data());
        setFinishLoad(true);
      });
  }, []);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={8}>
        <Typography
          component="h1"
          variant="h1"
          style={{
            fontFamily: "MostraThree",
            fontSize: 50,
            color: "#00638F",
            textDecoration: "none",
            textAlign: "center",
            margin: "50px auto",
          }}
        >
          MAINT & SEA
        </Typography>
        <Button
          color="primary"
          variant="outlined"
          startIcon={<ArrowBack color="primary" />}
          className={classes.backButton}
          onClick={() => history.goBack()}
        >
          Retour
        </Button>
        <div
          style={{
            padding: "5%",
          }}
        >
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              marginTop: 20,
              color: "#00638F",
              fontSize: 40,
              textAlign: "center",
            }}
          >
            Relevé machine
          </Typography>
          {finishLoad ? (
            <>
              <Typography
                component="h4"
                variant="h5"
                style={{
                  fontFamily: "Poppins",
                  marginTop: 20,
                  color: "#00638F",
                  fontSize: 30,
                  textAlign: "center",
                }}
              >
                Dates de mission
              </Typography>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  marginTop: 20,
                  marginBottom: 20,
                  fontSize: 25,
                  textAlign: "center",
                }}
              >
                Du {moment(releve.dates.start.toDate()).format("DD/MM/YYYY")} au{" "}
                {moment(releve.dates.end.toDate()).format("DD/MM/YYYY")}
              </Typography>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Relevé machine
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ width: "100%" }}>
                    {releve.details &&
                      releve.details.map((el, i) => {
                        return (
                          <Accordion key={i}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.heading}>
                                {moment(el.time.toDate()).format(
                                  "[Le] DD/MM/YYYY [à] HH:mm:ss"
                                )}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    flexWrap: "wrap",
                                    gap: 20,
                                  }}
                                >
                                  <div>
                                    <Typography className={classes.subtitle}>
                                      Route/Mission
                                    </Typography>
                                    <Typography>
                                      {el.mission?.value || "Non renseigné"}
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography className={classes.subtitle}>
                                      Moteur
                                    </Typography>
                                    <Typography>
                                      {el.moteur?.label || "Non renseigné"}
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography className={classes.subtitle}>
                                      Heures moteur
                                    </Typography>
                                    <Typography>
                                      {el.hours || "Non renseigné"}
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography className={classes.subtitle}>
                                      Tours minute
                                    </Typography>
                                    <Typography>
                                      {el.tours || "Non renseigné"}
                                    </Typography>
                                  </div>
                                </div>
                                {el.main.map((main, i) => {
                                  return (
                                    <div key={i}>
                                      <Typography className={classes.subtitle}>
                                        {main.title}
                                      </Typography>
                                      {main.data.map((data, _i) => (
                                        <div
                                          style={{
                                            margin: 10,
                                            paddingLeft: 10,
                                            borderLeftWidth: 1,
                                            borderLeftStyle: "solid",
                                            borderLeftColor: "gray",
                                          }}
                                          key={_i}
                                        >
                                          <Typography
                                            className={classes.subtitle}
                                          >
                                            {data.label}
                                          </Typography>
                                          <Typography>
                                            {data.value
                                              ? data.value +
                                                " " +
                                                (data.unit || "")
                                              : "Non renseigné"}
                                          </Typography>
                                        </div>
                                      ))}
                                    </div>
                                  );
                                })}
                                {el.additional.map((main, i) => {
                                  return (
                                    <div key={i}>
                                      {main?.data &&
                                        main.data.map((data, _i) => (
                                          <div
                                            style={{
                                              margin: 10,
                                              paddingLeft: 10,
                                              borderLeftWidth: 1,
                                              borderLeftStyle: "solid",
                                              borderLeftColor: "gray",
                                            }}
                                            key={_i}
                                          >
                                            <Typography
                                              className={classes.subtitle}
                                            >
                                              {data.label}
                                            </Typography>
                                            <Typography>
                                              {data.value
                                                ? data.value +
                                                  " " +
                                                  (data.unit || "")
                                                : "Non renseigné"}
                                            </Typography>
                                          </div>
                                        ))}
                                    </div>
                                  );
                                })}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Rapport mission
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <Typography className={classes.subtitle}>
                      Détail mission
                    </Typography>
                    <Typography>
                      {releve.missionDetail || "Non renseigné"}
                    </Typography>
                    <Typography className={classes.subtitle}>
                      Moteurs
                    </Typography>

                    {releve.moteur?.length ? (
                      <ul>
                        {releve.moteur?.map((moteur, i) => (
                          <li key={i}>
                            <Typography>
                              Moteur : {moteur?.label || "Non renseigné"} |
                              Heures moteur début :{" "}
                              {releve.hours[i].start || "Non renseigné"} |
                              Heures moteur fin :{" "}
                              {releve.hours[i].end || "Non renseigné"}
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <Typography>Non renseigné</Typography>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Consommation
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <Typography className={classes.subtitle}>
                      Carburant
                    </Typography>
                    <Typography>
                      {releve.go.qty
                        ? releve.go.qty + " " + releve.go.unit
                        : "Non renseigné"}
                    </Typography>
                    <Typography className={classes.subtitle}>
                      Huile moteur
                    </Typography>
                    <Typography>
                      {releve.huile.qty
                        ? releve.huile.qty + " " + releve.huile.unit
                        : "Non renseigné"}
                    </Typography>
                    <Typography className={classes.subtitle}>
                      Huile inverseur/réducteur
                    </Typography>
                    <Typography>
                      {releve.inverseur.qty
                        ? releve.inverseur.qty + " " + releve.inverseur.unit
                        : "Non renseigné"}
                    </Typography>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Travaux à effectuer
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <Typography className={classes.subtitle}>
                      Pont/Graissage
                    </Typography>
                    <Typography>
                      {releve.travaux.pont
                        ? releve.travaux.pont
                        : "Non renseigné"}
                    </Typography>
                    <Typography className={classes.subtitle}>
                      Mécanique
                    </Typography>
                    <Typography>
                      {releve.travaux.meca
                        ? releve.travaux.meca
                        : "Non renseigné"}
                    </Typography>
                    <Typography className={classes.subtitle}>
                      Électrique
                    </Typography>
                    <Typography>
                      {releve.travaux.elec
                        ? releve.travaux.elec
                        : "Non renseigné"}
                    </Typography>
                    <Typography className={classes.subtitle}>
                      Forge/Tuyauterie
                    </Typography>
                    <Typography>
                      {releve.travaux.forge
                        ? releve.travaux.forge
                        : "Non renseigné"}
                    </Typography>
                  </div>
                </AccordionDetails>
              </Accordion>
            </>
          ) : (
            <LinearProgress color="primary" />
          )}
        </div>
      </Grid>
    </Grid>
  );
}
