import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import html2canvas from "html2canvas";
import {
  Redirect,
  useHistory,
  Link,
  useRouteMatch,
  Switch,
  Route,
  useParams,
  useLocation,
} from "react-router-dom";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Text,
  ResponsiveContainer,
} from "recharts";
import InfosRecap from "./HomeComponents/InfosRecap";
import CahierTableau from "./HomeComponents/CahierTable";
import MaintenanceTableau from "./HomeComponents/MaintenanceTable";
import HydrocarbureTableau from "./HomeComponents/HydroTable";
import MyCalendar from "./HomeComponents/MyCalendar";
import { AccessTime, Storage as StorageIcon } from "@material-ui/icons";
import Storage from "./HomeComponents/Storage";
import Horodatage from "./HomeComponents/Horodatage";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Checklist from "./HomeComponents/Checklist";

import ReleveTable from "./HomeComponents/ReleveTable";
import SubscribeReleve from "./HomeComponents/SubscribeReleve";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/welcome.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: "15%",
    borderRadius: 25,
    backgroundColor: "#00638F",
  },
  navbar: {
    padding: theme.spacing(0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "stretch",
    marginBottom: 20,
    flexWrap: "wrap",
  },
  button: {
    textTransform: "none",
    margin: theme.spacing(0, 1),
    borderRadius: "50px",
    fontWeight: "normal",
    color: "#00638F",
    minWidth: 230,
    maxWidth: 350,
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  selectedButton: {
    flex: 1,
    textTransform: "none",
    margin: theme.spacing(0, 1),
    borderRadius: "50px",

    fontWeight: "normal",
    color: "#FFF",
    backgroundColor: "#00638F",
    "&:hover": {
      backgroundColor: "#00638F",
    },
    minWidth: 230,
    maxWidth: 350,
  },
  grid: {
    width: "100%",
  },
}));

export default function Home({ firebase }) {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const shipsRef = firebase.db.collection("Ships");

  const [ship, setShip] = useState(null);
  const { shipId } = useParams();
  let match = useRouteMatch("/ship/:shipId");
  const location = useLocation();

  useEffect(() => {
    shipsRef.doc(shipId).onSnapshot((doc) => {
      if (doc.data() === null) {
        history.replace("/dashboard");
      } else {
        setShip(doc.data());
      }
    });
  }, [shipId]);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid className={classes.grid}>
        <Typography
          component="h1"
          variant="h1"
          style={{
            fontFamily: "MostraThree",
            fontSize: 50,
            color: "#00638F",
            textDecoration: "none",
            margin: "50px 0",
            marginLeft: "15%",
          }}
        >
          MAINT & SEA
        </Typography>
        {(!ship?.accessLevel || ship?.accessLevel == "0") && ship && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              padding: 20,
            }}
          >
            <Typography color="error" style={{ margin: 5, fontSize: 20 }}>
              Il semble que vous n'ayez pas d'abonnement actif rattaché à ce
              navire
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/" + shipId + "/plan")}
            >
              Cliquez ici pour vous abonner
            </Button>
          </div>
        )}
        <div className={classes.navbar}>
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              color: "#00638F",
              fontSize: 30,
              marginBottom: 10,
            }}
          >
            {ship?.name}
          </Typography>
          <div className={classes.buttons}>
            <Button
              className={
                location.pathname === match.url + "/calendrier"
                  ? classes.selectedButton
                  : classes.button
              }
              startIcon={
                <img
                  style={{ width: 25, height: 25 }}
                  src={
                    location.pathname === match.url + "/calendrier"
                      ? require("../Icons/calendar_white.png")
                      : require("../Icons/calendar_blue.png")
                  }
                />
              }
              onClick={() => {
                history.push(match.url + "/calendrier");
              }}
            >
              Calendrier
            </Button>
            <Button
              className={
                location.pathname === match.url + "/cahier"
                  ? classes.selectedButton
                  : classes.button
              }
              startIcon={
                <img
                  style={{ width: 25, height: 25 }}
                  src={
                    location.pathname === match.url + "/cahier"
                      ? require("../Icons/tools_white.png")
                      : require("../Icons/tools_blue.png")
                  }
                />
              }
              onClick={() => {
                history.push(match.url + "/cahier");
              }}
            >
              Cahier machine
            </Button>
            <Button
              className={
                location.pathname === match.url + "/maintenance"
                  ? classes.selectedButton
                  : classes.button
              }
              startIcon={
                <img
                  style={{ width: 25, height: 25 }}
                  src={
                    location.pathname === match.url + "/maintenance"
                      ? require("../Icons/boat_white.png")
                      : require("../Icons/boat_blue.png")
                  }
                />
              }
              onClick={() => {
                history.push(match.url + "/maintenance");
              }}
            >
              Maintenance réglementaire
            </Button>
            <Button
              className={
                location.pathname === match.url + "/infos"
                  ? classes.selectedButton
                  : classes.button
              }
              startIcon={
                <img
                  style={{ width: 25, height: 25 }}
                  src={
                    location.pathname === match.url + "/infos"
                      ? require("../Icons/anchor_white.png")
                      : require("../Icons/anchor_blue.png")
                  }
                />
              }
              onClick={() => {
                history.push(match.url + "/infos");
              }}
            >
              Navire et équipage
            </Button>
            <Button
              className={
                location.pathname === match.url + "/horodatage"
                  ? classes.selectedButton
                  : classes.button
              }
              startIcon={
                <img
                  style={{ width: 25, height: 25, objectFit: "contain" }}
                  src={
                    location.pathname === match.url + "/horodatage"
                      ? require("../Icons/horo_white.png")
                      : require("../Icons/horo_blue.png")
                  }
                />
              }
              onClick={() => {
                history.push(match.url + "/horodatage");
              }}
            >
              Horodatage moteurs
            </Button>
            <Button
              className={
                location.pathname === match.url + "/checklist"
                  ? classes.selectedButton
                  : classes.button
              }
              startIcon={
                <img
                  style={{ width: 25, height: 25, objectFit: "contain" }}
                  src={
                    location.pathname === match.url + "/checklist"
                      ? require("../Icons/checklist_white.png")
                      : require("../Icons/checklist_blue.png")
                  }
                />
              }
              onClick={() => {
                history.push(match.url + "/checklist");
              }}
            >
              Checklists
            </Button>
            <Button
              className={
                location.pathname === match.url + "/releves"
                  ? classes.selectedButton
                  : classes.button
              }
              startIcon={
                <img
                  style={{ width: 25, height: 25 }}
                  src={
                    location.pathname === match.url + "/releves"
                      ? require("../Icons/releve_white.png")
                      : require("../Icons/releve_blue.png")
                  }
                />
              }
              onClick={() => {
                history.push(match.url + "/releves");
              }}
            >
              Relevé machine
            </Button>
            <Button
              className={
                location.pathname === match.url + "/hydrocarbure"
                  ? classes.selectedButton
                  : classes.button
              }
              startIcon={
                <img
                  style={{ width: 20, height: 25 }}
                  src={
                    location.pathname === match.url + "/hydrocarbure"
                      ? require("../Icons/hydro_white.png")
                      : require("../Icons/hydro_blue.png")
                  }
                />
              }
              onClick={() => {
                history.push(match.url + "/hydrocarbure");
              }}
            >
              Hydrocarbure
            </Button>{" "}
            <Button
              className={
                location.pathname === match.url + "/storage"
                  ? classes.selectedButton
                  : classes.button
              }
              startIcon={
                <StorageIcon
                  style={{
                    width: 25,
                    height: 25,
                    color:
                      location.pathname === match.url + "/storage"
                        ? "#FFF"
                        : "#00638F",
                  }}
                />
              }
              onClick={() => {
                history.push(match.url + "/storage");
              }}
            >
              Documents
            </Button>
          </div>
        </div>
        <div
          style={{
            padding: "5% 0",
            backgroundColor: "#FCFCFC",
            display: ship ? "block" : "none",
          }}
        >
          <Switch>
            <Route exact path={match.url + "/cahier"}>
              {ship?.accessLevel ? (
                <CahierTableau firebase={firebase} shipId={shipId} />
              ) : (
                <Typography
                  component="h3"
                  variant="h5"
                  style={{
                    fontFamily: "Poppins",
                    color: "#00638F",
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  Indisponible avec votre abonnement actuel, contactez nous à{" "}
                  <a href="mailto:maint@andsea.fr">maint@andsea.fr</a> pour
                  augmenter votre abonnement
                </Typography>
              )}
            </Route>
            <Route exact path={match.url + "/hydrocarbure"}>
              {ship?.accessLevel ? (
                <HydrocarbureTableau firebase={firebase} shipId={shipId} />
              ) : (
                <Typography
                  component="h3"
                  variant="h5"
                  style={{
                    fontFamily: "Poppins",
                    color: "#00638F",
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  Indisponible avec votre abonnement actuel, contactez nous à{" "}
                  <a href="mailto:maint@andsea.fr">maint@andsea.fr</a> pour
                  augmenter votre abonnement
                </Typography>
              )}
            </Route>
            <Route exact path={match.url + "/releves"}>
              {ship?.accesReleve ? (
                <ReleveTable firebase={firebase} shipId={shipId} />
              ) : (
                <>
                  <SubscribeReleve shipId={shipId} firebase={firebase} />
                </>
              )}
            </Route>
            <Route exact path={match.url + "/maintenance"}>
              {ship?.accessLevel > 1 ? (
                <MaintenanceTableau firebase={firebase} shipId={shipId} />
              ) : (
                <Typography
                  component="h3"
                  variant="h5"
                  style={{
                    fontFamily: "Poppins",
                    color: "#00638F",
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  Indisponible avec votre abonnement actuel, contactez nous à{" "}
                  <a href="mailto:maint@andsea.fr">maint@andsea.fr</a> pour
                  augmenter votre abonnement
                </Typography>
              )}
            </Route>
            <Route exact path={match.url + "/calendrier"}>
              {ship?.accessLevel > 2 ? (
                <MyCalendar firebase={firebase} shipId={shipId} />
              ) : (
                <Typography
                  component="h3"
                  variant="h5"
                  style={{
                    fontFamily: "Poppins",
                    color: "#00638F",
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  Indisponible avec votre abonnement actuel, contactez nous à{" "}
                  <a href="mailto:maint@andsea.fr">maint@andsea.fr</a> pour
                  augmenter votre abonnement
                </Typography>
              )}
            </Route>
            <Route exact path={match.url + "/infos"}>
              <InfosRecap firebase={firebase} shipId={shipId} ship={ship} />
            </Route>
            <Route exact path={match.url + "/storage"}>
              {ship?.accessLevel ? (
                <Storage firebase={firebase} shipId={shipId} ship={ship} />
              ) : (
                <Typography
                  component="h3"
                  variant="h5"
                  style={{
                    fontFamily: "Poppins",
                    color: "#00638F",
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  Indisponible avec votre abonnement actuel, contactez nous à{" "}
                  <a href="mailto:maint@andsea.fr">maint@andsea.fr</a> pour
                  augmenter votre abonnement
                </Typography>
              )}
            </Route>
            <Route exact path={match.url + "/horodatage"}>
              {ship?.accessLevel ? (
                <Horodatage firebase={firebase} shipId={shipId} ship={ship} />
              ) : (
                <Typography
                  component="h3"
                  variant="h5"
                  style={{
                    fontFamily: "Poppins",
                    color: "#00638F",
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  Indisponible avec votre abonnement actuel, contactez nous à{" "}
                  <a href="mailto:maint@andsea.fr">maint@andsea.fr</a> pour
                  augmenter votre abonnement
                </Typography>
              )}
            </Route>
            <Route exact path={match.url + "/checklist"}>
              {ship?.accessLevel ? (
                <Checklist firebase={firebase} shipId={shipId} ship={ship} />
              ) : (
                <Typography
                  component="h3"
                  variant="h5"
                  style={{
                    fontFamily: "Poppins",
                    color: "#00638F",
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  Indisponible avec votre abonnement actuel, contactez nous à{" "}
                  <a href="mailto:maint@andsea.fr">maint@andsea.fr</a> pour
                  augmenter votre abonnement
                </Typography>
              )}
            </Route>
          </Switch>
        </div>
      </Grid>
    </Grid>
  );
}
