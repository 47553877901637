import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyAf52Rhg3S7fPVz6rZIubfjl7hMo-x5cFY",
  authDomain: "maint-and-sea.firebaseapp.com",
  databaseURL:
    "https://maint-and-sea-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "maint-and-sea",
  storageBucket: "maint-and-sea.appspot.com",
  messagingSenderId: "569283169327",
  appId: "1:569283169327:web:356b89cc16fcb447188ada",
};

class Firebase {
  constructor() {
    if (!app.apps.length) {
      app.initializeApp(config);
    } else {
      app.app(); // if already initialized, use that one
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.firestore = app.firestore;
    this.storage = app.storage();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  // *** User API ***
  user = (uid) => this.db.ref(`Users/${uid}`);
}
export default Firebase;
