import React from "react";
import { Checkbox, makeStyles, FormControlLabel } from "@material-ui/core";
import { Field } from "react-final-form";

const useStyles = makeStyles((theme) => ({
  label: {
    color: "#707070",
    textAlign: "left",
  },
  formControl: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: 100,
    marginBottom: 40,
  },
  field: {
    width: 300,
    borderRadius: 12,
    borderColor: "#C1C1C1",
  },
  fullWidth: {
    borderRadius: 12,
    borderColor: "#C1C1C1",
  },
}));

export default function InputCheckbox({
  name,
  label,

  color = "primary",
  required = false,
  value,
  ownOnChange = (e, checked) => {},
}) {
  const classes = useStyles();

  return (
    <Field name={name} type="checkbox">
      {(props) => (
        <FormControlLabel
          control={
            <Checkbox
              name={name}
              color={color}
              required={required}
              value={props.input.value}
              onChange={(e, checked) => {
                ownOnChange(e, checked);
                props.input.onChange(e, checked);
              }}
              checked={props.input.checked}
              inputProps={{ required }}
            />
          }
          label={label}
        />
      )}
    </Field>
  );
}
