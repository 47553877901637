import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Usurpate = ({ firebase }) => {
  const [cookies, setCookie, removeCookie] = useCookies();
  let query = useQuery();
  const history = useHistory();
  useEffect(() => {
    const token = query.get("token");
    console.log(token);
    if (token) {
      firebase.auth
        .signInWithCustomToken(token)
        .then(({ user }) => {
          if (user.email) {
            setCookie("userToken", user.uid, {
              path: "/",
              maxAge: 86400,
            });
            setCookie("email", user.email, {
              path: "/",
              maxAge: 86400,
            });
            console.log(user.email);
            history.replace("/dashboard");
          } else {
            removeCookie("userToken");
            removeCookie("email");

            history.replace("/");
          }
        })
        .catch((error) => {
          removeCookie("userToken");
          removeCookie("email");

          history.replace("/");
        });
    } else {
      history.replace("/");
    }
  }, [query]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />{" "}
    </div>
  );
};

export default Usurpate;
