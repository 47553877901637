import axios from "axios";

const LOCALURL = "http://127.0.0.1:8000/";
const DEVURL = "https://maintandsea.fly.dev/";
const PRODURL = "";
export const URL = DEVURL;

export const axiosInstance = axios.create({
  baseURL: DEVURL,
  timeout: 8000,
  responseType: "json",
  headers: { "X-Custom-Header": "foobar" },
});

export default function RequestAPI(method = "GET", url = "/", data = []) {
  return axios({
    method: method,
    url: URL + url,
    data: data,
    responseType: "json",
    headers: { "Access-Control-Allow-Origin": "*" },
  });
}

export function RequestAPIAuth(
  method = "GET",
  url = "/",
  data = [],
  token = ""
) {
  return axios({
    method: method,
    url: URL + url,
    data: data,
    responseType: "json",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + token,
    },
  });
}
