import React from "react";
import Routes from "./Routes.js";
import { theme } from "./Theme";
import { ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { CookiesProvider } from "react-cookie";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/fr";
import { fr } from "date-fns/locale";
import Firebase from "./Firebase.js";
import FirebaseContext from "./FirebaseContext.js";
import TopRightLogo from "./Components/TopRightLogo.js";
import { makeStyles } from "@material-ui/core/styles";


function App() {
  const locale = "fr";
  window.__localeId__ = "fr";
  const classes = useStyles();  

  return (
    <div className={classes.fullPage}>
      <FirebaseContext.Provider value={new Firebase()}>
        <SnackbarProvider maxSnack={3}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
            <ThemeProvider theme={theme}>
              <TopRightLogo />
              <Routes ></Routes>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </FirebaseContext.Provider>
    </div>
    
  );
}


const useStyles = makeStyles((theme) => ({
  fullPage: {
    overflowX: "hidden" ,
    position: "relative"
  },
}));


export default App;
