import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FinalForm from "../Utils/FinalForm";
import InputText from "../Inputs/InputText";
import RequestAPI from "../Utils/API";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
import { Redirect, useHistory, useParams } from "react-router-dom";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { hashSync } from "bcryptjs";
import { ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/welcome.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "50%",
    borderRadius: 25,
    backgroundColor: "#00638F",
  },
  backButton: {
    marginLeft: 20,
    marginTop: 20,
  },
}));

export default function Sailors({ firebase }) {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { shipId } = useParams();
  const history = useHistory();
  const ref = firebase.db.collection("Sailors");
  const handleSubmit = async (values) => {
    const userId = cookies.userToken;
    const hash = hashSync(values.password);

    console.log(hash);
    ref
      .doc(values.identifiant)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          enqueueSnackbar("Cet identifiant est déjà utilisé !", {
            variant: "error",
          });
          return;
        }
        firebase.db
          .collection("Sailors")
          .doc(values.identifiant)
          .set({
            name: values.nom,
            password: hash,
            armateur: userId,
            ship: shipId,
          })
          .then(() => {
            enqueueSnackbar("Marin ajouté !", { variant: "success" });
          })
          .catch(() => {
            enqueueSnackbar("Erreur lors de l'ajout du marin", {
              variant: "error",
            });
          });
      });
  };

  function checkSubscription() {
    firebase.db
      .collection("Ships")
      .doc(shipId)
      .get()
      .then((doc) => {
        const ship = doc.data();
        if (!ship.accessLevel) {
          enqueueSnackbar(
            "Veuillez séléctinner un abonnement avant d'inscrire des marins",
            {
              variant: "warning",
            }
          );
          history.replace("/" + shipId + "/plan");
        }
      });
  }
  useEffect(() => {
    checkSubscription();
  }, []);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={8}>
        <Typography
          component="h1"
          variant="h1"
          style={{
            fontFamily: "MostraThree",
            fontSize: 50,
            color: "#00638F",
            textDecoration: "none",
            textAlign: "center",
            margin: "50px auto",
          }}
        >
          MAINT & SEA
        </Typography>
        <Button
          color="primary"
          variant="outlined"
          startIcon={<ArrowBack color="primary" />}
          className={classes.backButton}
          onClick={() => history.goBack()}
        >
          Retour
        </Button>
        <div
          style={{
            padding: "5%",
          }}
        >
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              marginTop: 50,
              color: "#00638F",
              fontSize: 40,
              textAlign: "center",
            }}
          >
            Ajouter un marin (accès à l'application mobile)
          </Typography>
          <FinalForm onSubmit={handleSubmit} style={classes.form}>
            <InputText name="nom" label="Nom et prénom" required></InputText>
            <InputText
              name="identifiant"
              label="Identifiant (à utiliser sur l'application mobile)"
              required
            ></InputText>
            <InputText
              name="password"
              label="Mot de passe (à utiliser sur l'application mobile)"
              required
              type="password"
            ></InputText>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Créer
              </Button>
            </div>
          </FinalForm>
        </div>
      </Grid>
    </Grid>
  );
}
