import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FinalForm from "../Utils/FinalForm";
import InputText from "../Inputs/InputText";
import RequestAPI from "../Utils/API";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
import { Redirect, useHistory, Link } from "react-router-dom";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import InputSelect from "../Inputs/InputSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/welcome.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "50%",
    borderRadius: 25,
    backgroundColor: "#00638F",
  },
  inputSemi: {
    width: "48%",
  },
  inputsLine1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  grid: {
    width: "100%",
  },
}));

export default function Dashboard({ firebase }) {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const shipsRef = firebase.db.collection("Ships");
  const [coques, setCoques] = useState([]);
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    firebase.db
      .collection("ActivityTypes")
      .get()
      .then((snapshot) => {
        let tmp = [];

        snapshot.forEach((item) => {
          tmp.push({
            label: item.data().value,
            id: item.data().value,
          });
        });
        console.log("activities", tmp);

        setActivities(tmp);
      });
    firebase.db
      .collection("CoqueTypes")
      .get()
      .then((snapshot) => {
        let tmp = [];

        snapshot.forEach((item) => {
          tmp.push({
            label: item.data().value,
            id: item.data().value,
          });
        });
        console.log("coques", tmp);
        setCoques(tmp);
      });
  }, []);

  const handleSubmit = async (values) => {
    const userId = cookies.userToken;
    if (!values.activityType) {
      enqueueSnackbar("Merci de préciser un type d'activité", {
        variant: "error",
      });
      return;
    }
    shipsRef
      .add({ ...values, armateur: userId, createdAt: new Date() })
      .then((val) => {
        enqueueSnackbar("Navire ajouté !", { variant: "success" });
        enqueueSnackbar("Séléctionnez maintenant une formule", {
          variant: "info",
        });
        history.push(`/${val.id}/plan`);
      })
      .catch(() => {
        enqueueSnackbar("Erreur lors de l'ajout du navire", {
          variant: "error",
        });
      });
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid className={classes.grid}>
        <Typography
          component="h1"
          variant="h1"
          style={{
            fontFamily: "MostraThree",
            fontSize: 50,
            color: "#00638F",
            textDecoration: "none",
            margin: "50px 0",
            marginLeft: "15%",
          }}
        >
          MAINT & SEA
        </Typography>
        <div
          style={{
            padding: "5%",
          }}
        >
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontFamily: "Poppins",
              color: "#00638F",
              fontSize: 40,
              textAlign: "center",
            }}
          >
            Ajouter un navire
          </Typography>
          <FinalForm onSubmit={handleSubmit} style={classes.form}>
            <div className={classes.inputsLine1}>
              <div className={classes.inputSemi}>
                <InputText name="name" label="Nom navire" required></InputText>
              </div>

              <div className={classes.inputSemi}>
                <InputText
                  name="responsableName"
                  label="Nom Responsable"
                  required
                ></InputText>
              </div>
            </div>

            <div className={classes.inputDesc}>
              <InputText
                name="description"
                label="Description"
                multiline
              ></InputText>
            </div>
            <InputText name="immatriculation" label="Immatriculation" />
            <div className={classes.inputsLine1}>
              <div className={classes.inputSemi}>
                <InputText
                  name="width"
                  label="Largeur (m)"
                  type="number"
                  required
                ></InputText>
              </div>

              <div className={classes.inputSemi}>
                <InputText
                  name="length"
                  type="number"
                  label="Longueur (m)"
                  required
                ></InputText>
              </div>
            </div>
            <div className={classes.inputsLine1}>
              <div className={classes.inputSemi}>
                <InputText name="draught" label="Tirant d'eau (m)"></InputText>
              </div>

              <div className={classes.inputSemi}>
                <InputText name="port" label="Port d'attache"></InputText>
              </div>
            </div>
            <div className={classes.inputsLine1}>
              <div className={classes.inputSemi}>
                <InputText name="jauge" label="Jauge brut"></InputText>
              </div>

              <div className={classes.inputSemi}>
                <InputText name="motorisation" label="Motorisation"></InputText>
              </div>
            </div>
            <div className={classes.inputsLine1}>
              <div className={classes.inputSemi}>
                <InputText
                  name="power"
                  label="Puissance propulsive (kW)"
                ></InputText>
              </div>

              <div className={classes.inputSemi}>
                <InputText
                  name="auxiliary"
                  label="Moteur auxiliaire"
                ></InputText>
              </div>
            </div>
            <div className={classes.inputsLine1}>
              <div className={classes.inputSemi}>
                <InputText
                  name="navigationCategory"
                  label="Catégorie de navigation"
                ></InputText>
              </div>

              <div className={classes.inputSemi}>
                <InputText
                  name="constructionYear"
                  label="Année de construction"
                ></InputText>
              </div>
            </div>
            <div className={classes.inputsLine1}>
              <div className={classes.inputSemi}>
                <InputText name="MMSI" label="Numéro MMSI"></InputText>
              </div>

              <div className={classes.inputSemi}>
                <InputText
                  name="maxCapacity"
                  label="Nombre maximal de personnes à bord"
                ></InputText>
              </div>
            </div>
            <div className={classes.inputsLine1}>
              <div className={classes.inputSemi}>
                <InputSelect
                  content={activities}
                  name="activityType"
                  label="Type d'activité"
                  required
                />
              </div>

              <div className={classes.inputSemi}>
                <InputSelect
                  content={coques}
                  name="coque"
                  label="Type de coque"
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Créer
              </Button>
            </div>
          </FinalForm>
        </div>
      </Grid>
    </Grid>
  );
}
