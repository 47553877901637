import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useCookies } from "react-cookie";
import SignInSide from "./Pages/Login";
import Register from "./Pages/Register";
import FirebaseContext from "./FirebaseContext";
import Dashboard from "./Pages/Dashboard";
import Sailors from "./Pages/Sailors";
import Home from "./Pages/Home";
import Plan from "./Pages/Plan";
import Iban from "./Pages/Iban";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AppContainer from "./Containers/AppContainer";
import PrivateRoute from "./Utils/PrivateRoute";
import AddMaintenance from "./Pages/AddMaintenance";
import AddCahier from "./Pages/AddCahier";
import AddHydrocarbure from "./Pages/AddHydrocarbure";
import AddChecklist from "./Pages/AddChecklist";
import UpdateMaintenance from "./Pages/UpdateMaintenance";
import UpdateHydrocarbure from "./Pages/UpdateHydrocarbure";
import Sailor from "./Pages/Sailor";
import EditCahier from "./Pages/EditCahier";
import EditMaintenance from "./Pages/EditMaintenance";
import AddPartenaire from "./Pages/AddPartenaire";
import Rapport from "./Components/Rapport";
import Usurpate from "./Pages/Usurpate";
import Redirect from "./Pages/Redirect";
import Releve from "./Pages/Releve";

const stripePromise = loadStripe(
  "pk_live_51IXVUjIDhJTsGLwo3sQOAMxN0YuLDMHhWF9JnBM5l8nKTOrtPjmb0MEJI1nBaM5RymGtIGfg5fXP5G4J9tT0rBBN00jhUT8qPD"
);

export default function Routes() {
  const [cookies, setCookie] = useCookies();

  return (
    <Router>
      <Switch>
        {/* LOGOUT ROUTES */}
        <Route path="/login">
          <FirebaseContext.Consumer>
            {(firebase) => <SignInSide firebase={firebase} />}
          </FirebaseContext.Consumer>
        </Route>
        <PrivateRoute path="/rapport">
          <FirebaseContext.Consumer>
            {(firebase) => <Rapport firebase={firebase} />}
          </FirebaseContext.Consumer>
        </PrivateRoute>
        <Route path="/register">
          <FirebaseContext.Consumer>
            {(firebase) => <Register firebase={firebase} />}
          </FirebaseContext.Consumer>
        </Route>
        <Route path="/usurpate">
          <FirebaseContext.Consumer>
            {(firebase) => <Usurpate firebase={firebase} />}
          </FirebaseContext.Consumer>
        </Route>
        <PrivateRoute path="/">
          <FirebaseContext.Consumer>
            {(firebase) => (
              <AppContainer firebase={firebase}>
                <Switch>
                  <PrivateRoute path="/dashboard">
                    <FirebaseContext.Consumer>
                      {(firebase) => <Dashboard firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/partenaire">
                    <FirebaseContext.Consumer>
                      {(firebase) => <AddPartenaire firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/:shipId/plan">
                    <FirebaseContext.Consumer>
                      {(firebase) => <Plan firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/:shipId/payment">
                    <FirebaseContext.Consumer>
                      {(firebase) => (
                        <Elements stripe={stripePromise}>
                          <Iban firebase={firebase} />
                        </Elements>
                      )}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/:shipId/sailors">
                    <FirebaseContext.Consumer>
                      {(firebase) => <Sailors firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute exact path="/ship/:shipId/sailor/:id">
                    <FirebaseContext.Consumer>
                      {(firebase) => <Sailor firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/ship/:shipId">
                    <FirebaseContext.Consumer>
                      {(firebase) => <Home firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>

                  <PrivateRoute path="/:shipId/addCahier">
                    <FirebaseContext.Consumer>
                      {(firebase) => <AddCahier firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/:shipId/:docId/editCahier">
                    <FirebaseContext.Consumer>
                      {(firebase) => <EditCahier firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/:shipId/:docId/editMaintenance">
                    <FirebaseContext.Consumer>
                      {(firebase) => <EditMaintenance firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/:shipId/addMaintenance">
                    <FirebaseContext.Consumer>
                      {(firebase) => <AddMaintenance firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/:shipId/addHydrocarbure">
                    <FirebaseContext.Consumer>
                      {(firebase) => <AddHydrocarbure firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/:shipId/addChecklist">
                    <FirebaseContext.Consumer>
                      {(firebase) => <AddChecklist firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/:shipId/maintenance/:eventId">
                    <FirebaseContext.Consumer>
                      {(firebase) => <UpdateMaintenance firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/:shipId/releve/:id">
                    <FirebaseContext.Consumer>
                      {(firebase) => <Releve firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                  <PrivateRoute path="/:shipId/hydrocarbure/:eventId">
                    <FirebaseContext.Consumer>
                      {(firebase) => <UpdateHydrocarbure firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>

                  <PrivateRoute exact path="/">
                    <FirebaseContext.Consumer>
                      {(firebase) => <Redirect firebase={firebase} />}
                    </FirebaseContext.Consumer>
                  </PrivateRoute>
                </Switch>
              </AppContainer>
            )}
          </FirebaseContext.Consumer>
        </PrivateRoute>
      </Switch>
    </Router>
  );
}
